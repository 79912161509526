<template>
  <p></p>
  <Filters
      :helpers="true"
      @onRunReport="runReport"
      :exportbut="rows.length>0"
      @onDownloadFile="downloadFile"
  />
  <p></p>
  <vue-good-table v-if="rows.length>0"
    styleClass="vgt-table nowrap bordered"
    :columns="columnsstat"
    :rows="rowsstat"
    :sort-options="{
      enabled: false,
    }"
    :row-style-class="rowStyleClassFn"
    compactMode>
    <template #table-row="props">
      {{props.row[props.column.field]}}
    </template>
  </vue-good-table>
  <p></p>
  <div v-if="rows.length<1" class="alert alert-info">{{ $t('reports.reportsdetailed.dataNotFound') }}</div>
    <el-table
        ref="tableEl"
        v-if="rows.length>0"
        :data="rows_part"
        height="60vh"
        style="width: 100%"
        border
        :scrollbar-always-on="true"
        :cell-class-name="cellStyleClassFnEl"
    >
      <el-table-column prop="check_data" :label="$t('reports.reportsdetailed.datetime')" />
      <el-table-column prop="bill_nameid">
        <template #header>
          {{$t('reports.reportsdetailed.check')}}
          <el-select v-model="filtersEl.bill_nameid" filterable clearable size="small" @change="filterTableDataFn">
            <el-option
                v-for="item in filtersOptionsEl.bill_nameid"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="check_num">
        <template #default="props">
        <span @click="to_check(props.row.check_num)">
            <span class="link">{{props.row.check_num}}</span>
          </span>
        </template>
        <template #header>
          {{$t('reports.reportsdetailed.receipt')}}
          <el-input
              v-model="filtersEl.check_num"
              size="small"
              @change="filterTableDataFn"
          />
        </template>
      </el-table-column>
      <el-table-column prop="src">
        <template #header>
          {{$t('reports.reportsdetailed.source')}}
          <el-select v-model="filtersEl.src" filterable clearable size="small" @change="filterTableDataFn">
            <el-option
                v-for="item in filtersOptionsEl.src"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="sum_bets_ch">
        <template #header>
          {{$t('reports.reportsdetailed.bid')}}
          <el-input
              v-model="filtersEl.sum_bets"
              size="small"
              :placeholder="$t('reports.reportsdetailed.more')"
              @change="filterTableDataFn"
          />
        </template>
      </el-table-column>
      <el-table-column prop="result" >
        <template #header>
          {{$t('reports.reportsdetailed.result')}}
          <el-select v-model="filtersEl.check_res" filterable clearable size="small" @change="filterTableDataFn">
            <el-option
                v-for="item in filtersOptionsEl.check_res"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="cashier_accept">
        <template #header>
          {{$t('reports.reportsdetailed.cashier')}}
          <el-select v-model="filtersEl.cashier_accept" filterable clearable size="small" @change="filterTableDataFn">
            <el-option
                v-for="item in filtersOptionsEl.cashier_accept"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="payout_date" :label="$t('reports.reportsdetailed.paymentDate')" />
      <el-table-column prop="payout_amount_ch">
        <template #header>
          {{$t('reports.reportsdetailed.payoutAmount')}}
          <el-input
              v-model="filtersEl.payout_amount"
              size="small"
              :placeholder="$t('reports.reportsdetailed.more')"
              @change="filterTableDataFn"
          />
        </template>
      </el-table-column>

      <el-table-column prop="cashier_paid">
        <template #header>
          {{$t('reports.reportsdetailed.cashier')}}
          <el-select v-model="filtersEl.cashier_paid" filterable clearable size="small" @change="filterTableDataFn">
            <el-option
                v-for="item in filtersOptionsEl.cashier_paid"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="reject_date" :label="$t('reports.reportsdetailed.rejectDate')" />
    </el-table>
</template>

<script setup>
import {inject, ref, onActivated, provide, computed, watch, onUpdated} from 'vue'
import {useStore} from "vuex";
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import ReportService from "@/services/report.service";
import Filters from "@/components/Reports/Filters/filters.vue";

const langEl=inject('langEl')
const dataformatEl=inject('dataformatEl')

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')
const $i18n = inject('$i18n')

const store = useStore()

const set_result = inject('set_result')

const is_super = inject('is_super')

const setIsLoading = inject('setIsLoading')

const runProcess = inject('runProcess')

const data_type = inject('data_type')

function filtersFn()
{
  let res=[]

  res.push({type: 'datestart'})
  res.push({type: 'dateend'})
  res.push({type: 'timezone'})

  if(is_super.value==true) {
    res.push({type: 'status'})
  }

  return res
}
const filters = computed(filtersFn)
provide('filters', filters)

const updateRunRep = inject('updateRunRep')
const updateTabActive = inject('updateTabActive')
const userfilterData = inject('filterData')
const userRunRep = inject('runRep')
const updatefilterData = inject('updatefilterData')
const downloadFileExcel = inject('downloadFileExcel')

const date_to_format = inject('date_to_format')

const tz_list = inject('tz_list')

const is_run = ref(false)
provide('is_run', is_run)

const date_start = ref('')
const date_end = ref('')
const value = ref('')
const filter_res = ref('all')
const filter_res_tab = ref('')
const tz_res = ref('UTC+0')


///////////
function TableHeightDetFn()
{
  const pageHeight = document.documentElement.scrollHeight

  return pageHeight+"px"
}
const TableHeightDet = computed(TableHeightDetFn)
const filtersEl = ref({'bill_nameid': '', 'src': '', 'check_num': '', 'sum_bets': '', 'payout_amount': '', 'check_res': '', 'cashier_accept': '', 'cashier_paid': ''})
const filtersOptionsEl = ref({'bill_nameid': [], 'src': [], 'cashier_accept': [], 'cashier_paid': [], 'check_res': []})

function filterHandler(val, row, column)
{
  let property = column['property']

  return val===row[property]
}

function filterTableDataFn()
{
  staticdata.value.receipt_all=0
  staticdata.value.receipt_show=0
  staticdata.value.bets_sum=0
  staticdata.value.paid_cnt=0
  staticdata.value.paid_sum=0
  staticdata.value.profit=0
  staticdata.value.not_paid_cnt=0
  staticdata.value.not_paid_sum=0
  staticdata.value.rejected=0

  let filterData = []

  for (let currow in rows.value) {
    let cur = rows.value[currow]
    let is_filter_ok = true

    //Счёт
    if (filtersEl.value.bill_nameid != '') {
      if (cur.bill_nameid == null || filtersEl.value.bill_nameid.includes(cur.bill_nameid) != true) {
        is_filter_ok = false
      }
    }

    //Источник
    if (filtersEl.value.src != '') {
      if (cur.src == null || filtersEl.value.src.includes(cur.src) != true) {
        is_filter_ok = false
      }
    }

    //Результат
    if (filtersEl.value.check_res != '') {
      if (cur.result == null || filtersEl.value.check_res.includes(cur.result) != true) {
        is_filter_ok = false
      }
    }

    //Кассир принял
    if (filtersEl.value.cashier_accept != '') {
      if (cur.cashier_accept == null || filtersEl.value.cashier_accept.includes(cur.cashier_accept) != true) {
        is_filter_ok = false
      }
    }

    //Кассир выплатил
    if (filtersEl.value.cashier_paid != '') {
      if (cur.cashier_paid == null || filtersEl.value.cashier_paid.includes(cur.cashier_paid) != true) {
        is_filter_ok = false
      }
    }

    //Номер чека
    if (filtersEl.value.check_num != '') {
      if (cur.check_num.toLowerCase().includes(filtersEl.value.check_num.toLowerCase()) != true) {
        is_filter_ok = false
      }
    }

    //Ставка
    if (filtersEl.value.sum_bets != '') {
      if (numbermore_cf(cur.sum_bets, filtersEl.value.sum_bets) != true) {
        is_filter_ok = false
      }
    }

    //Сумма выплаты
    if (filtersEl.value.payout_amount != '') {
      if (numbermore_cf(cur.payout_amount, filtersEl.value.payout_amount) != true) {
        is_filter_ok = false
      }
    }

    if (is_filter_ok == true) {
      filterData.push(cur)
    }


    //Расчеты статистики
    let crdt = to_date(cur['check_data_fjs'])

    let paid_dt=''
    if(cur['payout_date_fjs']!=null) {
      paid_dt = to_date(cur['payout_date_fjs'])
    }

    let reject_date=''
    if(cur['reject_date_fjs']!=null) {
      reject_date = to_date(cur['reject_date_fjs'])
    }

    //Чеков принято Всего - количество принятых чеков с датой принятия в рамках диапазона фильтра при построении отчета и результат не отмененные.
    if (cur['result_code'] != 'rejected' /*&& crdt >= userfilterData.ds && crdt < userfilterData.de*/) {
      staticdata.value.receipt_all++
    }

    if (is_filter_ok) {
      //Чеков принято Показано - Сколько из этих чеков отображается с учетом используемых фильтров
      if (cur['result_code'] != 'rejected' /*&& crdt >= userfilterData.ds && crdt < userfilterData.de*/) {
        staticdata.value.receipt_show++
      }

      //Сумма ставок. Учитываются только те чеки, где дата принятия в рамках диапазона фильтра при построении отчета и результат не отмененные.
      if (cur['result_code'] != 'rejected' && crdt >= userfilterData.ds && crdt < userfilterData.de) {
        if (cur['sum_bets'] != null) {
          staticdata.value.bets_sum = staticdata.value.bets_sum + parseFloat(cur['sum_bets'])
        }
      }

      //Количество выплаченных. Учитываются только те чеки, где дата выплаты в рамках диапазона фильтра при построении отчета и результат не отмененные.
      if (cur['result_code'] != 'rejected' && paid_dt >= userfilterData.ds && paid_dt < userfilterData.de) {
        staticdata.value.paid_cnt++
      }

      //Сумма выплат. Учитываются только те чеки, где дата выплаты в рамках диапазона фильтра при построении отчета и результат не отмененные.
      if (cur['result_code'] != 'rejected' && paid_dt >= userfilterData.ds && paid_dt < userfilterData.de) {
        if (cur['payout_amount'] != null) {
          staticdata.value.paid_sum = staticdata.value.paid_sum + parseFloat(cur['payout_amount'])
        }
      }

      //Не выплачено (шт). Количество чеков в статусе Выигрыш (не выплачено)
      if (cur['result_code'] == 'not_paid') {
        staticdata.value.not_paid_cnt++
      }

      //Сумма невыплаченных. Сумма к выплате (не возможный выигрыш, а именно к выплате) всех чеков в статусе Выигрыш (не выплачено).
      if (cur['result_code'] == 'not_paid') {
        if (cur['payout_amount'] != null) {
          staticdata.value.not_paid_sum = staticdata.value.not_paid_sum + parseFloat(cur['payout_amount'])
        }
      }

      //Отменено. Количество чеков в статусе отмененные, где дата отмены в рамках диапазона фильтра при построении отчета.
      if (cur['result_code'] == 'rejected' && reject_date >= userfilterData.ds && reject_date < userfilterData.de) {
        staticdata.value.rejected++
      }
    }
  }

  staticdata.value.profit=staticdata.value.bets_sum-staticdata.value.paid_sum //Прибыль. Сумма ставок - Сумма выплат

  staticdata.value.bets_sum=staticdata.value.bets_sum.toLocaleString().replace(',', '.')
  staticdata.value.paid_sum=staticdata.value.paid_sum.toLocaleString().replace(',', '.')
  staticdata.value.not_paid_sum=staticdata.value.not_paid_sum.toLocaleString().replace(',', '.')
  staticdata.value.profit=staticdata.value.profit.toLocaleString().replace(',', '.')

  PartNum.value=0
  rowsForParts.value=filterData
  getPartData()

  return filterData
}
//const filterTableData = computed(filterTableDataFn)

function cellStyleClassFnEl(data)
{
  let resclass = 'color_class_'+data.row.result_code

  if(['sum_bets_ch', 'payout_amount_ch'].includes(data.column.property)) {
    resclass+=' align_right'
  }

  return resclass
}
///////////////


const check_res = computed(() => [
  {"id": "all", "text": $t('reports.reportsdetailed.all')},
  {"id": "paid", "text": $t('reports.reportsdetailed.paid')},
  {"id": "not_paid", "text": $t('reports.reportsdetailed.not_paid')},
  {"id": "lost", "text": $t('reports.reportsdetailed.lost')},
  {"id": "rejected", "text": $t('reports.reportsdetailed.cancel')},
  {"id": "pending_confirmation", "text": $t('reports.reportsdetailed.pending_confirmation')}
])

const staticdata = ref({
      'receipt_all': 0,
      'receipt_show': 0,
      'bets_sum': 0,
      'paid_cnt': 0,
      'paid_sum': 0,
      'profit': 0,
      'not_paid_cnt': 0,
      'not_paid_sum': 0,
      'rejected': 0
})

const to_date = inject('to_date')

const columnsstat = computed(() => [
  {
    label: $t('reports.reportsdetailed.checksAcceptedTotal'),
    field: 'receipt_all_receipt_show',
  },
  {
    label: $t('reports.reportsdetailed.betAmount'),
    field: 'bets_sum',
  },
  {
    label: $t('reports.reportsdetailed.payout'),
    field: 'paid_sum',
  },
  {
    label: $t('reports.reportsdetailed.amountUnpaid'),
    field: 'not_paid_sum',
  },
  {
    label: $t('reports.reportsdetailed.profit'),
    field: 'profit',
  },
  {
    label: $t('reports.reportsdetailed.numberPaidOut'),
    field: 'paid_cnt',
  },
  {
    label: $t('reports.reportsdetailed.unpaidPcs'),
    field: 'not_paid_cnt',
  },
  {
    label: $t('reports.reportsdetailed.cancelled'),
    field: 'rejected',
  },
])

const rowsstat = computed(() => [{
  "receipt_all_receipt_show": staticdata.value.receipt_all+' / '+staticdata.value.receipt_show,
  "bets_sum": staticdata.value.bets_sum,
  "paid_cnt": staticdata.value.paid_cnt,
  "paid_sum": staticdata.value.paid_sum,
  "profit": staticdata.value.profit,
  "not_paid_cnt": staticdata.value.not_paid_cnt,
  "not_paid_sum": staticdata.value.not_paid_sum,
  "rejected": staticdata.value.rejected,
}])

const numbermore_cf = inject('numbermore_cf')

const filtersOptions = ref({'src': [], 'cashier_accept': [], 'cashier_paid': [], 'check_res': []})

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
        {
          label: $t('reports.reportsdetailed.datetime'),
          field: 'check_data',
        },
        {
          label: $t('reports.reportsdetailed.check'),
          field: 'bill_nameid',
          tdClass: 'text-right',
        },
        {
          label: $t('reports.reportsdetailed.receipt'),
          field: 'check_num',
          filterOptions: {enabled: true, placeholder: $t('reports.reportsdetailed.receipt')},
          tdClass: 'text-right',
        },
        {
          label: $t('reports.reportsdetailed.source'),
          field: 'src',
          filterOptions: {
            enabled: true,
            placeholder: $t('reports.reportsdetailed.all'),
            filterDropdownItems: filtersOptions.value.src
          },
        },
        {
          label: $t('reports.reportsdetailed.bid'),
          field: 'sum_bets_ch',
          filterOptions: {enabled: true, filterFn: numbermore_cf, placeholder: $t('reports.reportsdetailed.more')},
          tdClass: 'text-right',
        },
        {
          label: $t('reports.reportsdetailed.result'),
          field: 'result',
          filterOptions: {
            enabled: true,
            filterValue: filter_res_tab.value,
            placeholder: $t('reports.reportsdetailed.all'),
            filterDropdownItems: filtersOptions.value.check_res
          }
        },
        {
          label: $t('reports.reportsdetailed.cashier'),
          field: 'cashier_accept',
          filterOptions: {
            enabled: true,
            placeholder: $t('reports.reportsdetailed.all'),
            filterDropdownItems: filtersOptions.value.cashier_accept
          },
        },
        {
          label: $t('reports.reportsdetailed.paymentDate'),
          field: 'payout_date',
        },
        {
          label: $t('reports.reportsdetailed.payoutAmount'),
          field: 'payout_amount_ch',
          filterOptions: {enabled: true, filterFn: numbermore_cf, placeholder: $t('reports.reportsdetailed.more')},
          tdClass: 'text-right'
        },
        {
          label: $t('reports.reportsdetailed.cashier'),
          field: 'cashier_paid',
          filterOptions: {
            enabled: true,
            placeholder: $t('reports.reportsdetailed.all'),
            filterDropdownItems: filtersOptions.value.cashier_paid
          },
        },
        {
          label: $t('reports.reportsdetailed.rejectDate'),
          field: 'reject_date',
        }
      ])

const rows = ref([])
const rowsForParts = ref([])

const PartNum=ref(0)
const PartPerCnt=ref(50)
const isAddPart=ref(false)
const rows_part = ref([])
function getPartData(all=false)
{
  rows_part.value=[]

  if(rowsForParts.value.length>0) {
    PartNum.value++
    let to=PartNum.value*PartPerCnt.value
    isAddPart.value=true
    if(to>rowsForParts.value.length || all===true) {
      to=rowsForParts.value.length
      isAddPart.value=false
    }
    
    for(let i=1;i<=to;i++) {
      rows_part.value.push(rowsForParts.value[i-1])
    }
  }
}

/*function showAllPartData()
{
  PartNum.value=0
  getPartData(true)
}*/

const tableEl=ref(null)

function scroll(res)
{
  let height = res.target;

  let clientHeight = height.clientHeight;
  let scrollTop = height.scrollTop;
  let scrollHeight = height.scrollHeight;

  let cscrol=parseInt(scrollTop)+parseInt(clientHeight)
  let cscrolmin=cscrol-35
  let cscrolmax=cscrol+5

  if(scrollHeight>cscrolmin && scrollHeight<cscrolmax) {
    getPartData()
  }
}

const fristEvent=true
function upd()
{
  if(tableEl.value!=null) {
    tableEl.value.context.refs.bodyWrapper.addEventListener('scroll', scroll, true);
    fristEvent.value==false
  }
}
onUpdated(upd)


function onScroll(e)
{
  let chei=e.target.scrollHeight
  let cscrol=parseInt(e.target.scrollTop)+parseInt(e.target.clientHeight)
  let cscrolmin=cscrol-5
  let cscrolmax=cscrol+5
  if(chei>cscrolmin && chei<cscrolmax) {
    getPartData()
  }
}

function rowStyleClassFn(row)
{
  return 'color_class_'+row.result_code
}

function colStyleClassFn(row)
{
  let color_class='color_class_'+row.result_code

  if (row.result_code=='win') {
    color_class=color_class+'_'+row.status_check
  }

  return color_class
}

function runReport()
{
  setIsLoading(true)

  let dstart=date_to_format(userfilterData.ds)
  let dend=date_to_format(userfilterData.de)

  let bills=[];
  for(let key in store.state.bill.selBills) {
    let cur=store.state.bill.selBills[key];
      bills.push(cur)
  }

  rows.value=[]
  ReportService.getDetailed(data_type.value, bills, dstart, dend, userfilterData.tz, userfilterData.status).then(
      (response) => {
        filtersEl.value = {'bill_nameid': '', 'src': '', 'check_num': '', 'sum_bets': '', 'payout_amount': '', 'check_res': '', 'cashier_accept': '', 'cashier_paid': ''}

        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        let cur = []
        let bill_nameid = []
        let src = []
        let cashier_accept = []
        let cashier_paid = []
        let check_res = []

        for(let key in response.data) {
          cur = response.data[key]
          cur['result'] = $t('reports.reportsdetailed.' + cur['result'])
          rows.value.push(cur)

          if(cur['bill_nameid']!=null) {
            bill_nameid[cur['bill_nameid']]=cur['bill_nameid']
          }

          if(cur['src']!=null) {
            src[cur['src']]=cur['src']
          }

          if(cur['cashier_accept']!=null) {
            cashier_accept[cur['cashier_accept']]=cur['cashier_accept']
          }

          if(cur['cashier_paid']!=null) {
            cashier_paid[cur['cashier_paid']]=cur['cashier_paid']
          }

          if(cur['result']!=null) {
            check_res[cur['result']]=cur['result']
          }
        }

        filterTableDataFn()

        PartNum.value=0
        rowsForParts.value=rows.value
        getPartData()

        let filtersOptionsTmp={}
        filtersOptionsTmp.bill_nameid = Object.keys(bill_nameid)
        filtersOptionsTmp.src = Object.keys(src)
        filtersOptionsTmp.cashier_accept = Object.keys(cashier_accept)
        filtersOptionsTmp.cashier_paid = Object.keys(cashier_paid)
        filtersOptionsTmp.check_res = Object.keys(check_res)

        filtersOptionsTmp.bill_nameid.sort()
        filtersOptionsTmp.src.sort()
        filtersOptionsTmp.cashier_accept.sort()
        filtersOptionsTmp.cashier_paid.sort()
        filtersOptionsTmp.check_res.sort()

        filtersOptionsEl.value.bill_nameid=[]
        for(let key in filtersOptionsTmp.bill_nameid) {
          cur = filtersOptionsTmp.bill_nameid[key]
          filtersOptionsEl.value.bill_nameid.push({ text: cur, value: cur })
        }

        filtersOptionsEl.value.src=[]
        for(let key in filtersOptionsTmp.src) {
          cur = filtersOptionsTmp.src[key]
          filtersOptionsEl.value.src.push({ text: cur, value: cur })
        }

        filtersOptionsEl.value.cashier_accept=[]
        for(let key in filtersOptionsTmp.cashier_accept) {
          cur = filtersOptionsTmp.cashier_accept[key]
          filtersOptionsEl.value.cashier_accept.push({ text: cur, value: cur })
        }

        filtersOptionsEl.value.cashier_paid=[]
        for(let key in filtersOptionsTmp.cashier_paid) {
          cur = filtersOptionsTmp.cashier_paid[key]
          filtersOptionsEl.value.cashier_paid.push({ text: cur, value: cur })
        }

        filtersOptionsEl.value.check_res=[]
        for(let key in filtersOptionsTmp.check_res) {
          cur = filtersOptionsTmp.check_res[key]
          filtersOptionsEl.value.check_res.push({ text: cur, value: cur })
        }

        //rows.value=response.data
        is_run.value=true
        setIsLoading(false)
        runProcess.value=true
      },
      (error) => {
        set_result(error.response.status, error)
        setIsLoading(false)
        runProcess.value=true
      }
  )
}

function setPeriod(date)
{
  updatefilterData({"ds": date.date_s, "de": date.date_e})
}
provide('setPeriod', setPeriod)

function to_check(dt)
{
  updatefilterData({"chn": dt})

  updateTabActive('view-receipt')
  updateRunRep(true)
}

const table=ref(null)
function downloadFile()
{
  let replace_filed = {
    sum_bets_ch: 'sum_bets',
    payout_amount_ch: 'payout_amount',
  }

  downloadFileExcel(rowsForParts.value, columns.value, 'details', replace_filed)
}

const currentTab = inject('currentTab')
const i18nlocale = computed(() => $i18n.locale)

function onChangeLang()
{
  if(currentTab.value=='detailed') {
    runReport()
  }
}
watch(i18nlocale, onChangeLang)

onActivated(() => {
  if(userRunRep.value) {
    if (userfilterData.paid_status!='all') {
      //filter_res_tab.value=$t('reports.reportsdetailed.'+userfilterData.paid_status) //фильтрация через таблицы vue-good
      //filtersEl.value.check_res=$t('reports.reportsdetailed.'+userfilterData.paid_status) //фильтрация через таблицы el не работает
      filter_res.value=userfilterData.paid_status
    } else {
      filter_res_tab.value=''
    }
    runReport()
    updateRunRep(false)
  }
})
</script>

<style scoped>
.sidebar {
  height: 60vh;
  overflow-y: auto;
}
</style>

<style>
.color_class_not_played {
  color: black !important;
  font-weight: bold;
}

.color_class_paid {
  color: darkgreen !important;
  font-weight: bold;
}

.color_class_not_paid {
  color: lightgreen !important;
}

.color_class_lost {
  color: gray !important;
}

.color_class_rejected {
  color: red !important;
}

.color_class_pending_confirmation {
  color: #FFA040 !important;
}

.statistic {
  margin: 10px 0;
  border-color: #999999;
}

.statistic th {
  color: #999999;
}

.statistic td {
  text-align: center;
}
</style>