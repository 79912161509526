<template>
  <p></p>
  <Filters
      :helpers="true"
      @onRunReport="runReport"
  />
  <p></p>
  <div>
    <div v-if="isLoadingDivVirt"><img height="50" src="@/assets/loading.gif"/></div>
    <el-table
        ref="tableEl"
        v-if="rows.length>0"
        :data="rowsForParts"
        :span-method="objectSpanMethod"
        style="width: 100%"
        border
        :scrollbar-always-on="true"
        :cell-class-name="cellStyleClassFnEl"
    >
      <el-table-column v-for="column in columns" :key="column.field" :prop="column.field" :label="column.label" :min-width="column.minwidth">
        <template #header v-if="column.filtertype">
          {{ column.label }}<br>
          <el-select v-if="column.filtertype=='select'"
                     v-model="filtersEl[column.field]"
                     filterable
                     clearable
                     size="small"
                     @change="filterTableDataFn"
          >
            <el-option
                v-for="item in filtersOptionsEl[column.field]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>

          <el-input v-if="column.filtertype=='more'"
              v-model="filtersEl[column.field]"
              size="small"
              :placeholder="$t('reports.reportsdetailed.more')"
              @change="filterTableDataFn"
          />
        </template>
      </el-table-column>
    </el-table>

    <div class="alert alert-info" v-if="rows.length<1">{{ $t('reports.reportsoverview.dataNotFound') }}</div>
  </div>
</template>

<script setup>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {provide, inject, ref, computed, watchEffect, watch} from "vue";
import {VueGoodTable} from "vue-good-table-next";
import ReportService from "@/services/report.service";
import Filters from "@/components/Reports/Filters/filters.vue";

import {useStore} from "vuex";
import Loading from 'vue3-loading-overlay';

const langEl=inject('langEl')
const dataformatEl=inject('dataOnlyformatEl')

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const is_super = inject('is_super')

const store = useStore()

const set_result = inject('set_result')

const firstRun=ref(true)

const isLoadingDivVirt=ref(false)
const isLoadingDivTable=ref(false)
const setIsLoading = inject('setIsLoading')

const runProcess = inject('runProcess')

const data_type = inject('data_type')

const numbermore_cf = inject('numbermore_cf')

function filtersFn()
{
  let res=[]

  res.push({type: 'datestart'})
  res.push({type: 'dateend'})
  res.push({type: 'timezone'})

  return res
}
const filters = computed(filtersFn)
provide('filters', filters)

const userfilterData = inject('filterData')
const updatefilterData = inject('updatefilterData')
const downloadFileExcel = inject('downloadFileExcel')

const date_to_format = inject('date_to_format')

const tz_list = inject('tz_list')

const is_run = ref(false)
provide('is_run', is_run)

const rep_type_list = computed(()=>[
  {id: 'byData', name: $t('reports.reportsoverview.byData')},
  {id: 'byBillParent', name: $t('reports.reportsoverview.byBillParent')}
])
const rep_type = ref('byData')

const tblCnt=inject('tblCnt')
const cntrep=ref(0)

const date_start = ref('')
const date_end = ref('')
const tz_res = ref('UTC+0')

const perPageDropdown = inject('perPageDropdown')

const filtersEl = ref({'bill_fullname': '', 'game_fullname': '', 'cnt_bet_id': '', 'sum_bets_sum': ''})
const filtersOptionsEl = ref({'bill_fullname': [], 'game_fullname': []})

function fields()
{
  let fld = []

  fld.push({label: $t('reports.reportspublic.virt.bill'), field: 'bill_fullname', minwidth: '40', filterval: '', filtertype: 'select'})
  fld.push({label: $t('reports.reportspublic.virt.game_fullname'), field: 'game_fullname', minwidth: '40', filterval: '', filtertype: 'select'})
  fld.push({label: $t('reports.reportspublic.virt.count_receipt'), field: 'count_receipt', minwidth: '10', filterval: '', filtertype: 'more'})
  fld.push({label: $t('reports.reportspublic.virt.sum_bets_sum'), field: 'sum_bets_sum', minwidth: '10', filterval: '', filtertype: 'more'})

  return fld
}
const columns = computed(fields)

const level = ref(0)

const rows = ref([])

const rowsForParts = ref([])

function filterTableDataFn()
{
  let filterData = []

  rowsForParts.value=[]
  for (let currow in rows.value) {
    let cur = rows.value[currow]
    let is_filter_ok = true

    for(let keyc in columns.value) {
      let cutc=columns.value[keyc]

      if(cutc.filtertype=='select') {
        if (filtersEl.value[cutc.field] != '') {
          if (cur[cutc.field] == null || filtersEl.value[cutc.field].includes(cur[cutc.field]) != true) {
            is_filter_ok = false
          }
        }
      }

      if(cutc.filtertype=='more') {
        if (filtersEl.value[cutc.field] != '') {
          if (cur[cutc.field] == null || numbermore_cf(cur[cutc.field], filtersEl.value[cutc.field])==false) {
            is_filter_ok = false
          }
        }
      }
    }

    if (is_filter_ok == true) {
      filterData.push(cur)
    }
  }

  rowsForParts.value=filterData
}

function objectSpanMethod(data)
{
  let rowspan_dt=1
  let colspan_dt=1

  if(data.column.property=='bill_fullname') {
    let cur_ri = data.rowIndex

    let first_data=rowsForParts.value[cur_ri]

    let rscnt=0
    do {
      rscnt++
    } while(rowsForParts.value[cur_ri+rscnt]!=undefined && rowsForParts.value[cur_ri+rscnt].bill_fullname==first_data.bill_fullname)

    if(rscnt>1) {
      rowspan_dt=rscnt
    }

    if(rowsForParts.value[cur_ri-1]!=undefined && rowsForParts.value[cur_ri-1].bill_fullname==first_data.bill_fullname) {
      rowspan_dt = 0
      colspan_dt = 0
    }
  }

  return {
    rowspan: rowspan_dt,
    colspan: colspan_dt,
  }
}

function runReportVirt(list_bills=null, cur_el=null, level=0, sel_bill=null)
{
  isLoadingDivVirt.value=true
  cntrep.value++

  let dstart=date_to_format(userfilterData.ds)
  let dend=date_to_format(userfilterData.de)

  let bills=[];
  for(let key in store.state.bill.selBills) {
    let cur=store.state.bill.selBills[key];
    bills.push(cur)
  }

  if(list_bills!==null) {
    bills=JSON.parse(list_bills)
  }

  rows.value=[]
  rowsForParts.value=[]
  ReportService.getVirtSurveyByGame(data_type.value, bills, dstart, dend, userfilterData.tz, sel_bill, userfilterData.convert_to_usd).then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        for(let keyc in filtersOptionsEl.value) {
          filtersOptionsEl.value[keyc]=[]
        }

        for(let key in response.data) {
          let cur = response.data[key]
          rows.value.push(cur)
          rowsForParts.value.push(cur)

          for(let keyc in filtersOptionsEl.value) {
            let dt = cur[keyc]
            filtersOptionsEl.value[keyc][dt]=dt
          }
        }

        for(let foel in filtersOptionsEl.value) {
          let curfoel=filtersOptionsEl.value[foel]
          curfoel = Object.keys(curfoel)
          curfoel.sort()

          let tmpobj=[]
          for(let cfoel in curfoel) {
            let curcfoel=curfoel[cfoel]
            tmpobj.push({ text: curcfoel, value: curcfoel})
          }

          filtersOptionsEl.value[foel]=tmpobj
        }

        is_run.value=true
        isLoadingDivVirt.value=false
        runProcess.value=true
      },
      (error) => {
        set_result(error.response.status, error)
        isLoadingDivVirt.value=false
        runProcess.value=true
      }
  )
}

function runReport()
{
  firstRun.value=true

  runReportVirt()
}

function setPeriod(date)
{
  updatefilterData({"ds": date.date_s, "de": date.date_e})
}
provide('setPeriod', setPeriod)

function cellStyleClassFnEl(data)
{
  let resclass=''

  if(data.row.level>0 && data.row.empty_lv==undefined) {
    if (data.row.level % 2 == 0) {
      resclass = 'bgcolor_gray2'
    } else {
      resclass = 'bgcolor_gray1'
    }
  }

  if(data.column.rawColumnKey=='profit_ch') {
    if(data.row.profit>0) {
      resclass='bgcolor_up'
    }

    if(data.row.profit<0) {
      resclass='bgcolor_down'
    }

    if(data.row.profit==0) {
      resclass='bg-white'
    }
  }

  if(data.column.rawColumnKey=='total_win_ch') {
    if(data.row.total_win>0) {
      resclass='bgcolor_up'
    }

    if(data.row.total_win<0) {
      resclass='bgcolor_down'
    }

    if(data.row.total_win==0) {
      resclass='bg-white'
    }
  }

  if(data.row.grpdt=='1') {
    resclass+=' font_bold'
  }

  if(['count_receipt', 'sum_bets_sum'].includes(data.column.rawColumnKey)) {
    resclass+=' align_right'
  }

  return resclass
}

function downloadFile()
{
  let replace_filed = {
    sum_bets_ch: 'sum_bets',
    sum_payout_amount_ch: 'sum_payout_amount',
    profit_ch: 'profit',
  }

  downloadFileExcel(rows.value, columns.value, 'overview', replace_filed)
}

function numberWithSpaces(x)
{
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const formatFLoat=inject('formatFLoat')
</script>
