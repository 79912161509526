<template>
  <p></p>
  <!--div v-if="isselbill=!true" class="container-fluid">{{ $t('reports.reports.pleaseSelectAt') }}</div-->
  <div class="container-fluid" >
    <ul class="nav nav-tabs">
      <li class="nav-item cursor-pointer btn m-0 p-0"
          v-for="tab in tabs"
          :key="tab.code"
          :class="['tab-button', { active: currentTab === tab.code}]"
          @click="updateTabActive(tab.code)">{{tab.isselbill}}
        <a v-if="tab.shownoselbill==true || cntSelBill>=1" class="nav-link" :class="['tab-button', { active: currentTab === tab.code}]">{{ tab.text }}</a>
      </li>
    </ul>

    <ul v-if="currentTab.indexOf('virt')!=-1 && cntSelBill>=1" class="nav nav-tabs">
      <li class="nav-item cursor-pointer btn m-0 p-0"
          v-for="tab_virt in tabs_virt"
          :key="tab_virt.code"
          :class="['tab-button', { active: currentTabVirt === tab_virt.code }]"
          @click="updateTabVirtActive(tab_virt.code)">
        <a class="nav-link" :class="['tab-button', { active: currentTabVirt === tab_virt.code }]">{{ tab_virt.text }}</a>
      </li>
    </ul>

    <ul v-if="currentTab.indexOf('table')!=-1  && cntSelBill>=1" class="nav nav-tabs">
      <li class="nav-item cursor-pointer btn m-0 p-0"
          v-for="tab_table in tabs_table"
          :key="tab_table.code"
          :class="['tab-button', { active: currentTabTable === tab_table.code }]"
          @click="updateTabTableActive(tab_table.code)">
        <a class="nav-link" :class="['tab-button', { active: currentTabTable === tab_table.code }]">{{ tab_table.text }}</a>
      </li>
    </ul>

    <ul v-if="currentTab.indexOf('service')!=-1" class="nav nav-tabs">
      <li class="nav-item cursor-pointer btn m-0 p-0"
          v-for="tab_service in tabs_service"
          :key="tab_service.code"
          :class="['tab-button', { active: currentTabService === tab_service.code }]"
          @click="updateTabServiceActive(tab_service.code)">
        <a v-if="tab_service.shownoselbill==true || cntSelBill>=1" class="nav-link" :class="['tab-button', { active: currentTabService === tab_service.code }]">{{ tab_service.text }}</a>
      </li>
    </ul>
    <keep-alive>
      <component :is="currentTabComponent"></component>
    </keep-alive>
  </div>
</template>

<script setup>
import ReportsPublic from "@/components/Reports/ReportsPublic.vue";
import ReportsOverview from "@/components/Reports/Virt/ReportsOverview.vue";
import ReportsOverviewGroupBill from "@/components/Reports/Virt/ReportsOverviewGroupBill.vue";
import ReportsDetailed from "@/components/Reports/Virt/ReportsDetailed.vue";
import ReportsViewReceipt from "@/components/Reports/ReportsViewReceipt.vue";
import ReportsTableTransactionsDetail from "@/components/Reports/Table/ReportsTableTransactionsDetail.vue";
import ReportsTableConsolidates from "@/components/Reports/Table/ReportsTableConsolidates.vue";
import ReportsTableWorkingShift from "@/components/Reports/Table/ReportsTableWorkingShift.vue";
import ReportsTableDetailedBets from "@/components/Reports/Table/ReportsTableDetailedBets.vue";
import ReportsTableOverview from "@/components/Reports/Table/ReportsTableOverview.vue";
import ReportsJackpot from "@/components/Reports/Jackpot/ReportsJackpot.vue";
import EventsDetailed from "@/components/Reports/Events/Detailed.vue";
import BillsDelta from "@/components/Reports/Services/BillsDelta.vue";
import ReportsVirtSurveyByGame from "@/components/Reports/Virt/ReportsVirtSurveyByGame.vue";

import {computed, inject, onMounted, provide, reactive, ref, watch, watchEffect} from 'vue'
import XLSX from 'xlsx';
import {useRouter} from 'vue-router'
import {useStore} from "vuex";

const $t = inject('$t')

const router = useRouter()

const store = useStore()

const is_super = inject('is_super')

const currentUser = inject('currentUser')
const cntSelBill = inject('cntSelBill')

const langEl=inject('langEl')
const dataformatEl=inject('dataformatEl')

let date_start=ref(new Date());
let cday=new Date();
let date_end=ref(new Date(cday.setDate(cday.getDate()+1)))

date_start = new Date(date_start.value.toDateString())
date_end = new Date(date_end.value.toDateString())

const runProcess = inject('runProcess')

const runRep = ref(false)
const currentTab = ref('public')
const filterData = reactive({
  ds: date_start,
  de: date_end,
  tz: 'UTC+0',
  paid_status: 'all',
  chn: '',
  convert_to_usd: false,
  status: 'all'
})

provide('currentTab', currentTab)

const check_res = computed(() => [
  {"id": "all", "text": $t('reports.reportsdetailed.all')},
  {"id": "paid", "text": $t('reports.reportsdetailed.paid')},
  {"id": "not_paid", "text": $t('reports.reportsdetailed.not_paid')},
  {"id": "lost", "text": $t('reports.reportsdetailed.lost')},
  {"id": "rejected", "text": $t('reports.reportsdetailed.cancel')},
  {"id": "pending_confirmation", "text": $t('reports.reportsdetailed.pending_confirmation')}
])
provide('check_res', check_res)

const tabs_prep = ref([])
const tblCnt = computed(() => store.state.bill.tblCnt)
provide('tblCnt', tblCnt)

const currentTabVirt = ref('overview-by-bill')
provide('currentTabOverview', currentTabVirt)

const tabs_virt = ref([])
tabs_virt.value.push({"code": "overview-by-bill", "text": $t('reports.reports.byBillParent')})
tabs_virt.value.push({"code": "detailed", "text": $t('reports.reports.DetailedReport')})
tabs_virt.value.push({"code": "reports-virt-survey-by-game", "text": $t('reports.reports.VirtSurveyByGame')})
tabs_virt.value.push({"code": "overview-by-data", "text": $t('reports.reports.byData')})

const currentTabTable = ref('table-consolidates')
provide('currentTabTable', currentTabTable)

const tabs_table = ref([])
tabs_table.value.push({"code": "table-consolidates", "text": $t('reports.reports.TableConsolidates')})
tabs_table.value.push({"code": "table-detailed-bets", "text": $t('reports.reports.TableDetailedBets')})
tabs_table.value.push({"code": "table-overview", "text": $t('reports.reports.TableOverview')})
tabs_table.value.push({"code": "table-workin-shift", "text": $t('reports.reports.TableWorkinShift')})
tabs_table.value.push({"code": "table-transaction", "text": $t('reports.reports.TableTransaction')})

const currentTabService = ref('events-detail')
provide('currentTabService', currentTabService)

const tabs_service = ref([])
tabs_service.value.push({"code": "events-detail", "text": $t('reports.reports.EventsDetail'), "shownoselbill": true})
tabs_service.value.push({"code": "bills_delta", "text": $t('reports.reports.BillsDelta')})

function fill_tabs(nw)
{
  tabs_prep.value.push({"code": "public", "text": $t('reports.reports.Public')})
  tabs_prep.value.push({"code": "virt", "text": $t('reports.reports.Virt')})
  if(nw>0) {
    tabs_prep.value.push({"code": "table", "text": $t('reports.reports.Table')})
  }
  tabs_prep.value.push({"code": "view-receipt", "text": $t('reports.reports.CheckReceipt'), "shownoselbill": true})
  tabs_prep.value.push({"code": "jackpot", "text": $t('reports.reports.jackpot')})

  if(is_super.value==true) {
    tabs_prep.value.push({"code": "service", "text": $t('reports.reports.Service'), "shownoselbill": true})
  }
}

function wtblCnt(nw)
{
  tabs_prep.value=[]

  fill_tabs(nw)
}
watch(tblCnt, wtblCnt)

fill_tabs(tblCnt.value)

const tabs = computed(() => tabs_prep.value)

function currentTabComponent_fn()
{
  let curComp = ReportsOverview

  if(currentTab.value=='public') {
    curComp = ReportsPublic
  }

  if(currentTab.value=='virt') {
    if (currentTabVirt.value == 'overview-by-data') {
      curComp = ReportsOverview
    }

    if (currentTabVirt.value == 'overview-by-bill') {
      curComp = ReportsOverviewGroupBill
    }

    if(currentTabVirt.value=='detailed') {
      curComp = ReportsDetailed
    }

    if(currentTabVirt.value=='reports-virt-survey-by-game') {
      curComp = ReportsVirtSurveyByGame
    }
  }

  if(currentTab.value=='view-receipt') {
    curComp = ReportsViewReceipt
  }

  if(currentTab.value=='table') {
    if (currentTabTable.value == 'table-transaction') {
      curComp = ReportsTableTransactionsDetail
    }

    if (currentTabTable.value == 'table-consolidates') {
      curComp = ReportsTableConsolidates
    }

    if (currentTabTable.value == 'table-workin-shift') {
      curComp = ReportsTableWorkingShift
    }

    if (currentTabTable.value == 'table-detailed-bets') {
      curComp = ReportsTableDetailedBets
    }

    if (currentTabTable.value == 'table-overview') {
      curComp = ReportsTableOverview
    }
  }

  if (currentTab.value == 'jackpot') {
    curComp = ReportsJackpot
  }

  if (currentTab.value == 'service') {
    if (currentTabService.value == 'events-detail') {
      curComp = EventsDetailed
    }
    if (currentTabService.value == 'bills_delta') {
      curComp = BillsDelta
    }
  }


  return curComp
}

const currentTabComponent = computed(currentTabComponent_fn)

const currentUserTZ = computed( ()=>currentUser.value.timezone)

function updateRunRep (data)
{
  runRep.value = data
}

function updateTabActive (data)
{
  currentTab.value = data
}

function updateTabVirtActive (data)
{
  currentTabVirt.value = data
}

function updateTabTableActive (data)
{
  currentTabTable.value = data
}

function updateTabServiceActive (data)
{
  currentTabService.value = data
}

function updatefilterData (data)
{
  if(data.ds!=undefined) {
    filterData.ds = data.ds
  }
  if(data.de!=undefined) {
    filterData.de = data.de
  }
  if(data.tz!=undefined) {
    filterData.tz = data.tz
  }
  if(data.chn!=undefined) {
    filterData.chn = data.chn
  }
  if(data.paid_status!=undefined) {
    filterData.status = data.paid_status
  }
}

provide('updateTabActive', updateTabActive)
provide('updateTabTableActive', updateTabTableActive)
provide('updateTabVirtActive', updateTabVirtActive)
provide('updateTabServiceActive', updateTabServiceActive)
provide('filterData', filterData)
provide('updatefilterData', updatefilterData)
provide('runRep', runRep)
provide('updateRunRep', updateRunRep)

const runRepRemoteData=inject('runRepRemoteData')
function runRepRemoteFn()
{
  if(runRepRemoteData.value!=null) {
    updatefilterData({"chn": runRepRemoteData.value})

    updateTabActive('view-receipt')
    updateRunRep(true)

    runRepRemoteData.value=null
  }
}
watchEffect(runRepRemoteFn)

onMounted(() => {
  updatefilterData({tz: currentUserTZ.value})
})
</script>

<style>
.el-table__header th .cell, .vgt-table th {
  text-align: center;
}
</style>