<template>
  <p></p>
  <div class="col">
    <button @click="runReport(list_bills)" class="btn btn-success m-1 mr-4"><span v-if="!is_run">{{ $t('reports.reportsoverview.build') }}</span><span v-else>{{ $t('reports.reportsoverview.update') }}</span></button>
    <label><input type="checkbox" v-model="displayDelta"> {{$t('reports.reportsdelta.displayDelta')}} </label>
    <!-- <button v-if="exportbut" @click="downloadFile" class="btn btn-primary m-1">{{ $t('reports.reportsdetailed.export') }}</button> -->
  </div>
  <p></p>
  <div class="alert alert-info" v-if="rows.length<1">{{ $t('reports.reportsoverview.dataNotFound') }}</div>
  <el-table
      ref="tableEl"
      v-if="rows.length>0"
      :data="allRows"
      height="60vh"
      style="width: 100%"
      border
  >
    <el-table-column prop="bill_id" width="200%">
      <template #header>
        {{$t('bill.bills.number')}}
        <el-input
            v-model="filtersEl.bill_id"
            :placeholder="$t('bill.bills.number')"
            @change="filterTableDataFn"
        />
      </template>
    </el-table-column>
    <el-table-column prop="bill_name">
      <template #header>
        {{$t('bill.bill.accountName')}} <br>
        <el-select v-model="filtersEl.bill_name" filterable clearable @change="filterTableDataFn">
          <el-option
              v-for="item in filtersOptionsEl.bill_name"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          />
        </el-select>
      </template>
    </el-table-column>
    <el-table-column prop="bill_delta" :sortable="true" :sort-method="sortCol('bill_delta')">
      <template #header>
        {{$t('reports.reportseventsdetailed.event_delta')}}
      </template>
      <template #default="{ row }">
        <div :class="colStyleClassFn(row)">
          {{ parseFloat(row.bill_delta).toFixed(2) }}
        </div>
      </template>
    </el-table-column>

  </el-table>

</template>

<script setup>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {provide, inject, ref, computed, watchEffect, watch, onUpdated} from "vue";
import {VueGoodTable} from "vue-good-table-next";
import ReportService from "@/services/report.service";
import Filters from "@/components/Reports/Filters/filters.vue";
import {useStore} from "vuex";

const langEl=inject('langEl')
const dataformatEl=inject('dataOnlyformatEl')

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const is_super = inject('is_super')

const store = useStore()

const set_result = inject('set_result')

const setIsLoading = inject('setIsLoading')

const runProcess = inject('runProcess')

const data_type = inject('data_type')

const updateRunRep = inject('updateRunRep')
const updateTabActive = inject('updateTabActive')

const displayDelta = ref(true)
watch(displayDelta, filterTableDataFn)

const tableEl=ref(null)

const filtersEl = ref({'bill_id': '', 'bill_name': '', 'bill_delta': ''})
const filtersOptionsEl = ref({'bill_name': []})

const allRows = ref([])

function filterTableDataFn()
{
  let filterData = []

  for (let currow in rows.value) {
    let cur = rows.value[currow]
    let allRows = cur
    let is_filter_ok = true

    if (displayDelta.value === true && Math.abs(parseFloat(cur.bill_delta)) <= 0.01) {
      is_filter_ok = false
    }

    //Номер счета
    if (filtersEl.value.bill_id != '') {
      if (cur.bill_id.includes(filtersEl.value.bill_id) != true) {
        is_filter_ok = false
      }
    }
    //Счёт
    if (filtersEl.value.bill_name != '') {
      if (cur.bill_name == null || filtersEl.value.bill_name != cur.bill_name) {
        is_filter_ok = false
      }
    }

    if (is_filter_ok == true) {
      filterData.push(cur)
    }
  }

   allRows.value = filterData

   return filterData
}

const is_run = ref(false)
provide('is_run', is_run)

const perPageDropdown = inject('perPageDropdown')

const columns = computed(() => [
  {
    label: $t('bill.bills.number'),
    field: 'bill_id',
    filterOptions: {enabled: true, placeholder: $t('bill.bills.number')},
  },
  {
    label: $t('bill.bills.name'),
    field: 'bill_name',
  },
  {
    label: $t('reports.reportseventsdetailed.event_delta'),
    field: 'bill_delta',
  }
])

const rows = ref([])

function colStyleClassFn(row) {
  const billDelta = parseFloat(row.bill_delta)
  let colorClass
  if (billDelta > 0) {
    colorClass = 'color_class_bill_refill'
  }
   else if  (billDelta < 0){
    colorClass =  'color_class_bill_withdraw'
  }
  return colorClass
}

function runReport(list_bills=null)
{
  setIsLoading(true)

  let bills=[];
  for(let key in store.state.bill.selBills) {
    let cur=store.state.bill.selBills[key];
    bills.push(cur)
  }

  if(list_bills!=null) {
    bills=JSON.parse(list_bills)
  }

  rows.value=[]
  ReportService.getBillDelta(data_type.value, bills).then(
      (response) => {
        filtersEl.value = {'bill_id': '', 'bill_name': '', 'bill_delta': ''}

        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        rows.value=response.data

        let cur = []
        let bill_name = []

        for (let key in rows.value) {
          cur = response.data[key]

          if(cur['bill_name']!=null) {
            bill_name[cur['bill_name']]=cur['bill_name']
          }
        }

        filterTableDataFn()

        let filtersOptionsTmp={}
        filtersOptionsTmp.bill_name = Object.keys(bill_name)

        filtersOptionsTmp.bill_name.sort()

        filtersOptionsEl.value.bill_name=[]
        for(let key in filtersOptionsTmp.bill_name) {
          cur = filtersOptionsTmp.bill_name[key]
          filtersOptionsEl.value.bill_name.push({ text: cur, value: cur })
        }

        is_run.value=true
        setIsLoading(false)
        runProcess.value=true
      },
      (error) => {
        set_result(error.response.status, error)
        setIsLoading(false)
        runProcess.value=true
      }
  )
}

function sortCol(column) {
  return (a, b) => {
    return parseFloat(b[column]) - parseFloat(a[column])
  }
}

</script>

<style>

.color_class_bill_refill {
  background-color: #dff0d8;
  color: #111111 !important;
}

.color_class_bill_withdraw {
  background-color: #f2dede;
  color: #111111 !important;
}

</style>
