<template>
  <p></p>
  <Filters @onRunReport="runReport"/>
  <p></p>
  <div v-if="rows.length>0">
    <div class="row">
      <div class="col">
        <p>{{ $t('reports.reportsviewreceipt.checkNumber') }} {{receipt_info.receipt_id}}</p>
        <p>{{ $t('reports.reportsviewreceipt.stakeAmount') }} {{receipt_info.sum_bets}}</p>
        <p v-if="false">{{ $t('reports.reportsviewreceipt.possibleWin') }} {{receipt_info.possible_winnings}}</p>
        <p>{{ $t('reports.reportsviewreceipt.status') }} {{ $t('reports.reportsviewreceipt.' + receipt_info.result_code) }} <span v-if="receipt_info.status_paid"> ({{ $t('reports.reportsviewreceipt.' + receipt_info.status_paid) }})</span></p>
        <p><span v-if="receipt_info.status_code=='paid'">{{ $t('reports.reportsviewreceipt.paidOut') }}</span><span v-else>{{ $t('reports.reportsviewreceipt.toPayoff') }}</span> {{ receipt_info.sum_payout }}</p>
        <p v-if="receipt_info.box_public_id">{{ $t('reports.reportsviewreceipt.box_public_id') }}: {{receipt_info.box_public_id}}</p>
        <p v-if="receipt_info.box_public_id">{{ $t('reports.reportsviewreceipt.balance_after_receipt') }}: {{receipt_info.balance_after_receipt}}</p>
        <p><button v-if="showCancelBut" :disabled="guard_dbl_click" class="btn btn-danger m-3" @click="receipt_cancel">{{ $t('reports.reportsviewreceipt.cancelCheck') }}</button></p>
        <p><button v-if="showPayotBut" :disabled="guard_dbl_click" class="btn btn-success m-3" @click="receipt_payout">{{ $t('reports.reportsviewreceipt.payoutCheck') }}</button></p>
      </div>
      <div class="col">
        <span v-html="receipt_html"></span>
      </div>
    </div>
    <p></p>
    <vue-good-table
        styleClass="vgt-table nowrap bordered"
        :columns="columns"
        :rows="rows"
        :pagination-options="{
          enabled: true,
          perPage: 100,
          perPageDropdown: perPageDropdown,
          nextLabel: $t('app.vue-good-table-next.nextLabel'),
          prevLabel: $t('app.vue-good-table-next.prevLabel'),
          rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
          ofLabel: $t('app.vue-good-table-next.ofLabel'),
          pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
          allLabel: $t('app.vue-good-table-next.allLabel')
        }"
        compactMode>
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
      </template>
    </vue-good-table>
  </div>
  <div class="alert alert-info" v-if="rows.length<1">{{ $t('reports.reportsviewreceipt.dataNotFound') }}</div>
</template>

<script setup>
import {inject, ref, computed, onActivated, provide} from "vue";
import {useStore} from "vuex";
import ReportService from "../../services/report.service";
import ReceiptService from "../../services/receipt.service";
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {VueGoodTable} from "vue-good-table-next";
import 'quill/dist/quill.snow.css';
import Filters from "@/components/Reports/Filters/filters.vue";

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const guard_dbl_click = ref(false)

const store = useStore()

const set_result = inject('set_result')

const setIsLoading = inject('setIsLoading')

const runProcess = inject('runProcess')

const data_type = inject('data_type')

const currentUser = inject('currentUser')

const is_super = inject('is_super')
const is_admin_sb = inject('is_admin_sb')

function filtersFn()
{
  let res=[]

  res.push({type: 'receipt_id'})

  return res
}
const filters = computed(filtersFn)
provide('filters', filters)

const updateRunRep = inject('updateRunRep')
const userfilterData = inject('filterData')
const userRunRep = inject('runRep')
const updatefilterData = inject('updatefilterData')

const is_run = ref(false)
provide('is_run', is_run)

const receipt_html = ref('')

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
        {
          label: $t('reports.reportsviewreceipt.date'),
          field: 'event_date',
          filterOptions: {enabled: true, placeholder: $t('reports.reportsviewreceipt.date')},
          width: '270px',
        },
        {
          label: $t('reports.reportsviewreceipt.description'),
          field: 'type_transactions',
          filterOptions: {enabled: true, placeholder: $t('reports.reportsviewreceipt.description')},
          width: '100px',
        },
        {
          label: $t('reports.reportsviewreceipt.amount'),
          field: 'money_size',
          filterOptions: {enabled: true, placeholder: $t('reports.reportsviewreceipt.amount')},
          width: '100px',
        },

        {
          label: $t('reports.reportsviewreceipt.cashier'),
          field: 'user_login',
          filterOptions: {enabled: true, placeholder: $t('reports.reportsviewreceipt.cashier')},
          width: '100px',
        },
        {
          label: $t('reports.reportsviewreceipt.id'),
          field: 'transaction_id',
          filterOptions: {enabled: true, placeholder: $t('reports.reportsviewreceipt.id')},
          width: '100px',
        },
      ])

const rows = ref([])

function runReport()
{
  setIsLoading(true)

  let bills=[];
  for(let key in store.state.bill.selBills) {
    let cur=store.state.bill.selBills[key];
    bills.push(cur)
  }

  rows.value=[]
  ReportService.getReceipt(data_type.value, userfilterData.chn).then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        rows.value=response.data
        is_run.value=true
        setIsLoading(false)
        runProcess.value=true
      },
      (error) => {
        set_result(error.response.status, error)
        setIsLoading(false)
        runProcess.value=true
      }
  )

  getReceiptPrint(userfilterData.chn)
}

function getReceiptPrint (id)
{
  ReceiptService.getReceiptPrint(id).then(
      (response) => {
        receipt_html.value = response.data;
      },
      (error) => {
        set_result(error.response.status, error)
      }
  )
}

function receipt_cancel()
{
  guard_dbl_click.value = true

  const confirmation = confirm($t('reports.reportsviewreceipt.confirmtext'))

  if (confirmation) {
    ReceiptService.receiptCancel(receipt_info.value.receipt_id, receipt_info.value.secure_code).then(
        () => {
          set_result()
          runReport()

          guard_dbl_click.value = false
        },
        (error) => {
          let nam_err=''
          let err_data=error.response.data.err_info
          if(err_data.indexOf('###munis###')!=-1) {
            nam_err=$t('reports.reportsviewreceipt.amountexceeds')
          }

          set_result(error.response.status, error, nam_err)

          guard_dbl_click.value = false
        }
    )
  }
}

function receipt_payout()
{
  guard_dbl_click.value = true

  ReceiptService.receiptPayoutByAdmin(receipt_info.value.receipt_id).then(
      () => {
        set_result()
        runReport()

        guard_dbl_click.value = false
      },
      (error) => {
        let nam_err=''
        let err_data=error.response.data.err_info
        if(err_data.indexOf('###munis###')!=-1) {
          nam_err=$t('reports.reportsviewreceipt.amountexceeds')
        }

        set_result(error.response.status, error, nam_err)

        guard_dbl_click.value = false
      }
  )
}

const receipt_info = computed(() => {
  let ri=[]
  if(rows.value[0]!==undefined) {
    ri=rows.value[0]
  }
  return ri
})

function showCancelBut_fn()
{
  let res_acces = false;

  let res_reciept_not_played = receipt_info.value.status_code=='not_played'
  let res_reciept_playing = receipt_info.value.status_code=='playing'
  let res_reciept_lost = receipt_info.value.status_code=='lost'
  let res_reciept_not_paid = receipt_info.value.status_code=='not_paid'

  let res_reciept_paid = receipt_info.value.status_code=='paid'

  let res_bill_status = receipt_info.value.bill_status!='deleted'

  if (currentUser.value && currentUser.value['roles'] && res_bill_status) {
    if ((is_super.value===true || is_admin_sb.value===true)
        && (res_reciept_not_played || res_reciept_playing || res_reciept_lost || res_reciept_not_paid || res_reciept_paid)
        && receipt_info.value.box_public_id==null
    ) {
      res_acces = true
    }

    if (currentUser.value['roles'].includes('admin') && res_reciept_not_played) {
      res_acces = true
    }
  }

  return res_acces;
}
const showCancelBut=computed(showCancelBut_fn)

function showPayotBut_fn()
{
  let res_acces = false;

  let res_reciept_not_paid = receipt_info.value.status_code=='not_paid'

  let res_bill_status = receipt_info.value.bill_status!='deleted'

  if (currentUser.value && currentUser.value['roles'] && res_bill_status) {
    if (currentUser.value['roles'].includes('super')  && res_reciept_not_paid && receipt_info.value.box_public_id==null
    ) {
      res_acces = true
    }
  }

  return res_acces;
}
const showPayotBut=computed(showPayotBut_fn)

onActivated(() => {
  if(userRunRep.value) {
    runReport()
    updateRunRep(false)
  }
})
</script>

