<template>
  <p></p>
  <Filters
      :helpers="true"
      @onRunReport="runReport"
      :exportbut="rows.length>0"
      @onDownloadFile="downloadFile"
  />
  <p></p>
  <vue-good-table v-if="rows.length>1"
    styleClass="vgt-table nowrap bordered"
    :columns="columns"
    :rows="itogo"
    :sort-options="{
      enabled: false,
    }"
  compactMode>
    <template #emptystate>
      <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
    </template>
    <template #table-row="props">
        <span v-if="props.column.field == 'cnt_unic_bill_rem'" @click="to_det(props.row, props.column.field)">
          <span class="link">{{props.row[props.column.field]}}</span>
        </span>
      <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
    </template>
  </vue-good-table>
  <p></p>
  <vue-good-table v-if="rows.length>1"
      styleClass="vgt-table nowrap bordered"
      :columns="columns"
      :rows="datarows"
      :pagination-options="{
        enabled: true,
        perPage: 100,
        perPageDropdown: perPageDropdown,
        nextLabel: $t('app.vue-good-table-next.nextLabel'),
        prevLabel: $t('app.vue-good-table-next.prevLabel'),
        rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
        ofLabel: $t('app.vue-good-table-next.ofLabel'),
        pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
        allLabel: $t('app.vue-good-table-next.allLabel')
      }"
      compactMode>
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
      </template>
      <template #table-row="props">
        <span v-if="props.column.field == 'cnt_unic_bill' && props.row.cnt_list_bill!=0" @click="to_det(props.row)">
          <span class="link">{{props.row[props.column.field]}}</span>
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
  </vue-good-table>
  <div class="alert alert-info" v-if="rows.length<2">{{ $t('reports.reportsoverview.dataNotFound') }}</div>
</template>

<script setup>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {provide, inject, ref, computed, watchEffect, watch, onMounted} from "vue";
import {VueGoodTable} from "vue-good-table-next";
import ReportService from "@/services/report.service";
import Filters from "@/components/Reports/Filters/filters.vue";
import {useStore} from "vuex";

const langEl=inject('langEl')
const dataformatEl=inject('dataOnlyformatEl')

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const is_super = inject('is_super')

const store = useStore()

const set_result = inject('set_result')

const setIsLoading = inject('setIsLoading')

const runProcess = inject('runProcess')

const data_type = inject('data_type')

function filtersFn()
{
  let res=[]

  res.push({type: 'datestart'})
  res.push({type: 'dateend'})
  res.push({type: 'timezone'})
  res.push({type: 'game'})

  return res
}
const filters = computed(filtersFn)
provide('filters', filters)

const updateRunRep = inject('updateRunRep')
const updateTabActive = inject('updateTabActive')
const userfilterData = inject('filterData')
const updatefilterData = inject('updatefilterData')
const downloadFileExcel = inject('downloadFileExcel')

const date_to_format = inject('date_to_format')

const tz_list = inject('tz_list')

const is_run = ref(false)
provide('is_run', is_run)

const rep_type_list = computed(()=>[
  {id: 'byData', name: $t('reports.reportsoverview.byData')},
  {id: 'byBillParent', name: $t('reports.reportsoverview.byBillParent')}
])
const rep_type = ref('byData')

const date_start = ref('')
const date_end = ref('')
const tz_res = ref('UTC+0')

const perPageDropdown = inject('perPageDropdown')

function fields()
{
  let fld = []

  fld.push({
    label: $t('reports.reportsoverview.Agent'),
    field: 'bill_nameid_group',
    width: "120px"
  },
  {
    label: $t('reports.reportsoverview.ActiveAccounts'),
    field: 'cnt_unic_bill',
    tdClass: 'text-right',
  },
  {
    label: $t('reports.reportsoverview.StakeAmount'),
    field: 'sum_bets_ch',
    tdClass: 'text-right',
  },
  {
    label: $t('reports.reportsoverview.StakesPaid'),
    field: 'sum_payout_amount_ch',
    tdClass: 'text-right',
  },
  {
    label: $t('reports.reportsoverview.Profit'),
    field: 'profit_ch',
    tdClass: 'text-right',
  },
  {
    label: $t('reports.reportsoverview.RatesPaid'),
    field: 'cnt_check_paid',
    tdClass: 'text-right',
  },
  {
    label: $t('reports.reportsoverview.BetsAreDone'),
    field: 'cnt_check',
    tdClass: 'text-right',
  },
  {
    label: $t('reports.reportsoverview.CntCancel'),
    field: 'cnt_cancel',
    tdClass: 'text-right',
  })

  return fld
}
const columns = computed(fields)

const rows = ref([])

function changeType()
{
  rows.value=[]
}
watch(rep_type, changeType)

function runReport(list_bills=null)
{
  setIsLoading(true)

  let dstart=date_to_format(userfilterData.ds)
  let dend=date_to_format(userfilterData.de)

  let bills=[];
  for(let key in store.state.bill.selBills) {
    let cur=store.state.bill.selBills[key];
    bills.push(cur)
  }

  if(list_bills!==null) {
    bills=JSON.parse(list_bills)
  }

  rows.value=[]
  ReportService.getGroupByParentBill(data_type.value, bills, dstart, dend, userfilterData.tz, userfilterData.game).then(
    (response) => {
      verifyVer(response.headers.ver)
      verifyVerData(response.headers.verdata)

      rows.value=response.data
      is_run.value=true
      setIsLoading(false)
      runProcess.value=true
    },
    (error) => {
      set_result(error.response.status, error)
      setIsLoading(false)
      runProcess.value=true
    }
  )
}

function setPeriod(date)
{
  updatefilterData({"ds": date.date_s, "de": date.date_e})
}
provide('setPeriod', setPeriod)

function to_det(row)
{
  runReport(JSON.stringify(row.list_bill))
}

function downloadFile()
{
  let replace_filed = {
    sum_bets_ch: 'sum_bets',
    sum_payout_amount_ch: 'sum_payout_amount',
    profit_ch: 'profit',
  }

  downloadFileExcel(rows.value, columns.value, 'overview', replace_filed)
}

const itogo = computed( () => {
  let ri=[]
  let cr=[]
  if(rows.value[0]!==undefined) {
    cr=rows.value[0]
    cr['check_data']=$t('reports.reportsoverview.Total')
    ri.push(cr)
  }
  return ri
})

const datarows = computed(() => {
  return rows.value.slice(1)
})
</script>

<style scoped>
.link {
  cursor: pointer;
  color: blue;
  font-weight: bold;
}
</style>
