<template>
  <p></p>
  <Filters
      :helpers="true"
      @onRunReport="runReport"
      :exportbut="rows.length>0"
      @onDownloadFile="downloadFile"
  />
  <p></p>
  <div class="alert alert-info" v-if="rows.length<1">{{ $t('reports.reportsoverview.dataNotFound') }}</div>
  <vue-good-table v-if="rows.length>0"
      styleClass="vgt-table nowrap bordered"
      :columns="itog_columns"
      :rows="itog_rows"
      :sort-options="{
        enabled: false,
      }"
      compactMode>
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
      </template>
      <template #table-row="props">
        <span v-if="props.column.field == 'cnt_check' || props.column.field == 'cnt_check_paid' || props.column.field == 'cnt_cancel'" @click="to_det(props.row, props.column.field)">
          <span class="link">{{props.row[props.column.field]}}</span>
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
  </vue-good-table>
  <p></p>
  <el-table
      v-if="rows.length>0"
      :data="datarows"
      :cell-class-name="cellStyleClassFnEl"
      style="width: 100%"
      border
  >
    <el-table-column prop="table_name" :label="$t('reports.reportstableconsolidated.table_name')" width="180" />
    <el-table-column prop="bill_name_full" :label="$t('reports.reportstableconsolidated.bill_name_full')" />
    <el-table-column label-class-name="reports_rtc_static_trans" align="center" :label="$t('reports.reportstableconsolidated.transaction_statistic')">
      <el-table-column label-class-name="reports_rtc_static_trans" prop="sum_deposited_wallet_ch" :label="$t('reports.reportstableconsolidated.sum_deposited_wallet')" />
      <el-table-column label-class-name="reports_rtc_static_trans" prop="sum_deposited_cashier_ch" :label="$t('reports.reportstableconsolidated.sum_deposited_cashier')" />
      <el-table-column label-class-name="reports_rtc_static_trans" prop="sum_deposited_bonus_ch" :label="$t('reports.reportstableconsolidated.sum_deposited_bonus')" />
      <el-table-column label-class-name="reports_rtc_static_trans" prop="sum_paid_ch" :label="$t('reports.reportstableconsolidated.sum_paid')" />
      <el-table-column label-class-name="reports_rtc_static_trans" prop="total_win_ch" :label="$t('reports.reportstableconsolidated.total_win')" />
    </el-table-column>
    <el-table-column label-class-name="reports_rtc_static_bets" align="center" :label="$t('reports.reportstableconsolidated.betting_statistic')">
      <el-table-column label-class-name="reports_rtc_static_bets" prop="total_wagered_ch" :label="$t('reports.reportstableconsolidated.total_wagered')" />
      <el-table-column label-class-name="reports_rtc_static_bets" prop="total_paid_ch" :label="$t('reports.reportstableconsolidated.total_paid')" />
    </el-table-column>
    <el-table-column
        prop="jp_ch"
        :label="$t('reports.reportstableconsolidated.jp')"
    />
  </el-table>
</template>

<script setup>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {provide, inject, ref, computed} from "vue";
import {VueGoodTable} from "vue-good-table-next";
import ReportService from "../../../services/report.service";
import Filters from "@/components/Reports/Filters/filters.vue";
import {useStore} from "vuex";

const langEl=inject('langEl')
const dataformatEl=inject('dataformatEl')

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const store = useStore()

const set_result = inject('set_result')

const setIsLoading = inject('setIsLoading')

const runProcess = inject('runProcess')

const data_type = inject('data_type')

function filtersFn()
{
  let res=[]

  res.push({type: 'datestart'})
  res.push({type: 'dateend'})
  res.push({type: 'timezone'})

  return res
}
const filters = computed(filtersFn)
provide('filters', filters)

const updateRunRep = inject('updateRunRep')
const updateTabActive = inject('updateTabActive')
const userfilterData = inject('filterData')
const updatefilterData = inject('updatefilterData')
const downloadFileExcel = inject('downloadFileExcel')

const date_to_format = inject('date_to_format')

const tz_list = inject('tz_list')

const is_run = ref(false)
provide('is_run', is_run)

const date_start = ref('')
const date_end = ref('')
const tz_res = ref('UTC+0')

const perPageDropdown = inject('perPageDropdown')

const itog_columns = computed(() => [
  {
    label: $t('reports.reportstableconsolidated.sum_deposited_wallet'),
    field: 'sum_deposited_wallet_ch',
    tdClass: 'text-right',
  },
  {
    label: $t('reports.reportstableconsolidated.sum_deposited_cashier'),
    field: 'sum_deposited_cashier_ch',
    tdClass: 'text-right',
  },
  {
    label: $t('reports.reportstableconsolidated.sum_paid_sum'),
    field: 'sum_paid_ch',
    tdClass: 'text-right',
  },
  {
    label: $t('reports.reportstableconsolidated.total_win'),
    field: 'total_win_ch',
    tdClass: 'text-right',
  },
  {
    label: $t('reports.reportstableconsolidated.total_wagered_sum'),
    field: 'total_wagered_ch',
    tdClass: 'text-right',
  },
  {
    label: $t('reports.reportstableconsolidated.total_paid_sum'),
    field: 'total_paid_ch',
    tdClass: 'text-right',
  },
  {
    label: $t('reports.reportstableconsolidated.jp'),
    field: 'jp_ch',
    tdClass: 'text-right',
  }
])

const itog_rows = computed( () => {
  let ri=[]
  let cr=[]
  if(rows.value[0]!==undefined) {
    cr=rows.value[0]
    cr['check_data']=$t('reports.reportsoverview.Total')
    ri.push(cr)
  }
  return ri
})

const columns = computed(() => [
        {
          label: $t('reports.reportstableconsolidated.table_name'),
          field: 'table_name',
        },
        {
          label: $t('reports.reportstableconsolidated.bill_name_full'),
          field: 'bill_name_full',
        },
        {
          label: $t('reports.reportstableconsolidated.sum_deposited_wallet'),
          field: 'sum_deposited_wallet_ch',
          tdClass: 'text-right',
        },
        {
          label: $t('reports.reportstableconsolidated.sum_deposited_cashier'),
          field: 'sum_deposited_cashier_ch',
          tdClass: 'text-right',
        },
        {
          label: $t('reports.reportstableconsolidated.sum_deposited_bonus'),
          field: 'sum_deposited_bonus_ch',
          tdClass: 'text-right',
        },
        {
          label: $t('reports.reportstableconsolidated.sum_paid'),
          field: 'sum_paid_ch',
          tdClass: 'text-right',
        },
        {
          label: $t('reports.reportstableconsolidated.total_win'),
          field: 'total_win_ch',
          tdClass: 'text-right',
        },
        {
          label: $t('reports.reportstableconsolidated.total_wagered'),
          field: 'total_wagered_ch',
          tdClass: 'text-right',
        },
        {
          label: $t('reports.reportstableconsolidated.total_paid'),
          field: 'total_paid_ch',
          tdClass: 'text-right',
        },
        {
          label: $t('reports.reportstableconsolidated.jp'),
          field: 'jp_ch',
          tdClass: 'text-right',
        }
      ])

const datarows = computed(() => {
  return rows.value.slice(1)
})

const rows = ref([])

function runReport()
{
  setIsLoading(true)

  let dstart=date_to_format(userfilterData.ds)
  let dend=date_to_format(userfilterData.de)

  let bills=[];
  for(let key in store.state.bill.selBills) {
    let cur=store.state.bill.selBills[key];
    bills.push(cur)
  }

  rows.value=[]
  ReportService.getTableConsolidated(data_type.value, bills, dstart, dend, userfilterData.tz).then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        rows.value=response.data
        is_run.value=true
        setIsLoading(false)
        runProcess.value=true
      },
      (error) => {
        set_result(error.response.status, error)
        setIsLoading(false)
        runProcess.value=true
      }
  )
}

function setPeriod(date)
{
  updatefilterData({"ds": date.date_s, "de": date.date_e})
}
provide('setPeriod', setPeriod)

function to_det(row, field)
{
  let paid_status='all'

  /*if(field=='cnt_check') {
    paid_status = 'created'
  }*/

  if(field=='cnt_check_paid') {
    paid_status = 'paid'
  }

  if(field=='cnt_cancel') {
    paid_status = 'cancel'
  }

  updatefilterData({"paid_status": paid_status})

  if(row.grpdt!='1') {
    let dtt = row.dt.split('.')
    let dstart = new Date(dtt[2], dtt[1] - 1, dtt[0])
    let dend = new Date(dtt[2], dtt[1] - 1, dtt[0])
    dend.setDate(dend.getDate() + 1)
    updatefilterData({"ds": dstart, "de": dend})
  }

  updateTabActive('detailed')
  updateRunRep(true)
}

function downloadFile()
{
  let replace_filed = {
    sum_deposited_cashier_ch: 'sum_deposited_cashier',
    sum_deposited_wallet_ch: 'sum_deposited_wallet',
    sum_paid_ch: 'sum_paid',
    total_paid_ch: 'total_paid',
    total_wagered_ch: 'total_wagered',
    total_win_ch: 'total_win',
    jp_ch: 'jp',
  }

  downloadFileExcel(rows.value, columns.value, 'overview', replace_filed)
}

function cellStyleClassFnEl(data)
{
  let resclass=''

  if([
      'sum_deposited_wallet_ch',
      'sum_deposited_cashier_ch',
      'sum_deposited_bonus_ch',
      'sum_paid_ch',
      'total_win_ch',
      'total_wagered_ch',
      'total_paid_ch',
      'jp_ch',
    ].includes(data.column.property)) {
    resclass+=' align_right'
  }

  return resclass
}
</script>

<style scoped>
.link {
  cursor: pointer;
  color: blue;
  font-weight: bold;
}
</style>

<style>
.reports_rtc_static_trans {
  background-color: #DDDDDD !important;
}

.reports_rtc_static_bets {
  background-color: #CCCCCC !important;
}
</style>
