<template>
  <p></p>
  <Filters
      :helpers="true"
      @onRunReport="runReport"
      :exportbut="rows.length>0"
      @onDownloadFile="downloadFile"
  />
  <p></p>
  <div v-if="rows.length<1" class="alert alert-info">{{ $t('reports.reportsdetailed.dataNotFound') }}</div>
    <el-table
        ref="tableEl"
        v-if="rows.length>0"
        :data="rows_part"
        height="60vh"
        style="width: 100%"
        border
        :scrollbar-always-on="true"
        :cell-class-name="cellStyleClassFnEl"
    >
      <el-table-column prop="event_date" :label="$t('reports.reportseventsdetailed.event_date')" />
      <el-table-column prop="game_name">
        <template #header>
          {{$t('reports.reportseventsdetailed.game_name')}}<br>
          <el-select v-model="filtersEl.game_name" filterable clearable size="small" @change="filterTableDataFn">
            <el-option
                v-for="item in filtersOptionsEl.game_name"
                :key="item.value"
                :label="item.label"
                :value="item.value"
            />
          </el-select>
        </template>
      </el-table-column>
      <el-table-column prop="public_id">
        <template #header>
          {{$t('reports.reportseventsdetailed.public_id')}}<br>
          <el-input
              v-model="filtersEl.public_id"
              size="small"
              :placeholder="$t('reports.reportseventsdetailed.public_id')"
              @change="filterTableDataFn"
          />
        </template>
      </el-table-column>
      <el-table-column prop="event_result">
        <template #header>
          {{$t('reports.reportseventsdetailed.event_result')}}<br>
          <el-input
              v-model="filtersEl.event_result"
              size="small"
              :placeholder="$t('reports.reportseventsdetailed.event_result')"
              @change="filterTableDataFn"
          />
        </template>
      </el-table-column>
      <el-table-column prop="bets_count" :label="$t('reports.reportseventsdetailed.bets_count')" />
      <el-table-column prop="event_delta_ch" :label="$t('reports.reportseventsdetailed.event_delta')" />
    </el-table>
</template>

<script setup>
import {inject, ref, onActivated, provide, computed, watch, onUpdated, onMounted} from 'vue'
import {useStore} from "vuex";
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import ReportService from "@/services/report.service";
import Filters from "@/components/Reports/Filters/filters.vue";

const langEl=inject('langEl')
const dataformatEl=inject('dataformatEl')

const $t = inject('$t')
const $i18n = inject('$i18n')

const store = useStore()

const set_result = inject('set_result')

const setIsLoading = inject('setIsLoading')

function filtersFn()
{
  let res=[]

  res.push({type: 'datestart'})
  res.push({type: 'dateend'})
  res.push({type: 'timezone'})
  res.push({type: 'isbets'})

  return res
}
const filters = computed(filtersFn)
provide('filters', filters)

const userfilterData = inject('filterData')
const updatefilterData = inject('updatefilterData')
const downloadFileExcel = inject('downloadFileExcel')

const runProcess = inject('runProcess')

const date_to_format = inject('date_to_format')

const tz_list = inject('tz_list')

const is_run = ref(false)
provide('is_run', is_run)

const date_start = ref('')
const date_end = ref('')
const tz_res = ref('UTC+0')

const filtersEl = ref({'game_name': '', 'public_id': '', 'event_result': ''})
const filtersOptionsEl = ref({'game_name': []})

function filterHandler(val, row, column)
{
  let property = column['property']

  return val===row[property]
}

function filterTableDataFn()
{
  let filterData = []

  for (let currow in rows.value) {
    let cur = rows.value[currow]
    let is_filter_ok = true

    //Имя игры
    if (filtersEl.value.game_name != '') {
      if (cur.game_name == null || filtersEl.value.game_name.includes(cur.game_name) != true) {
        is_filter_ok = false
      }
    }

    if (filtersEl.value.public_id != '') {
      if (cur.public_id == null || filtersEl.value.public_id!=cur.public_id) {
        is_filter_ok = false
      }
    }

    if (filtersEl.value.event_result != '') {
      if (cur.event_result == null || filtersEl.value.event_result!=cur.event_result) {
        is_filter_ok = false
      }
    }

    if (is_filter_ok == true) {
      filterData.push(cur)
    }
  }

  PartNum.value=0
  rowsForParts.value=filterData
  getPartData()

  return filterData
}

function cellStyleClassFnEl(data)
{
  return 'color_class_'+data.row.result_code
}

const to_date = inject('to_date')

function numbermore_cf(data, filterString)
{
  var x
  var y

  if(filterString!=null) {
    x = parseInt(filterString.toString().replaceAll(' ', ''))
  }

  if(data!=null) {
    y = parseInt(data.toString().replaceAll(' ', ''))
  }

  return y >= x;
}

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
        {
          label: $t('reports.reportseventsdetailed.event_date'),
          field: 'event_date',
        },
        {
          label: $t('reports.reportseventsdetailed.game_name'),
          field: 'game_name',
        },
        {
          label: $t('reports.reportseventsdetailed.public_id'),
          field: 'public_id',
        },
        {
          label: $t('reports.reportseventsdetailed.event_result'),
          field: 'event_result',
        },
        {
          label: $t('reports.reportseventsdetailed.bets_count'),
          field: 'bets_count',
        },
        {
          label: $t('reports.reportseventsdetailed.event_delta'),
          field: 'event_delta_ch',
        }
      ])

const rows = ref([])
const rowsForParts = ref([])

const PartNum=ref(0)
const PartPerCnt=ref(50)
const isAddPart=ref(false)
const rows_part = ref([])
function getPartData(all=false)
{
  rows_part.value=[]

  if(rowsForParts.value.length>0) {
    PartNum.value++
    let to=PartNum.value*PartPerCnt.value
    isAddPart.value=true
    if(to>rowsForParts.value.length || all===true) {
      to=rowsForParts.value.length
      isAddPart.value=false
    }
    
    for(let i=1;i<=to;i++) {
      rows_part.value.push(rowsForParts.value[i-1])
    }
  }
}

/*function showAllPartData()
{
  PartNum.value=0
  getPartData(true)
}*/

const tableEl=ref(null)

function scroll(res)
{
  let height = res.target;

  let clientHeight = height.clientHeight;
  let scrollTop = height.scrollTop;
  let scrollHeight = height.scrollHeight;

  let cscrol=parseInt(scrollTop)+parseInt(clientHeight)
  let cscrolmin=cscrol-35
  let cscrolmax=cscrol+5

  if(scrollHeight>cscrolmin && scrollHeight<cscrolmax) {
    getPartData()
  }
}

const fristEvent=true
function upd()
{
  if(tableEl.value!=null) {
    tableEl.value.context.refs.bodyWrapper.addEventListener('scroll', scroll, true);
    fristEvent.value==false
  }
}
onUpdated(upd)

function runReport()
{
  setIsLoading(true)

  let dstart=date_to_format(userfilterData.ds)
  let dend=date_to_format(userfilterData.de)

  rowsForParts.value=[]
  ReportService.getEventsDetailed(dstart, dend, userfilterData.tz, userfilterData.isbets).then(
      (response) => {
        filtersEl.value = {'game_name': '', 'public_id': '', 'event_result': ''}

        rows.value=response.data
        let game_name = []
        for(let key in rows.value) {
          let cur = rows.value[key]
          rowsForParts.value.push(cur)

          if (cur['game_name'] != null) {
            game_name[cur['game_name']] = cur['game_name']
          }
        }

        let filtersOptionsTmp={}

        filtersOptionsTmp.game_name = Object.keys(game_name)
        filtersOptionsTmp.game_name.sort()

        filtersOptionsEl.value.game_name=[]
        for(let key in filtersOptionsTmp.game_name) {
          let cur = filtersOptionsTmp.game_name[key]
          filtersOptionsEl.value.game_name.push({ text: cur, value: cur })
        }

//        rowsForParts.value=response.data

        PartNum.value=0
        getPartData()

        is_run.value=true
        setIsLoading(false)
        runProcess.value=true
      },
      (error) => {
        let descr=''
        if(error.response.data.code_desct!==undefined) {
          descr=error.response.data.code_desct
        }

        set_result(error.response.status, error, descr)
        setIsLoading(false)
        runProcess.value=true
      }
  )
}

function setPeriod(date)
{
  updatefilterData({"ds": date.date_s, "de": date.date_e})
}
provide('setPeriod', setPeriod)

function downloadFile()
{
  downloadFileExcel(rowsForParts.value, columns.value, 'details')
}

onMounted(() => {userfilterData.isbets=true})
</script>

<style scoped>
.sidebar {
  height: 60vh;
  overflow-y: auto;
}
</style>

<style>
.link {
  cursor: pointer;
  color: blue;
}

.color_class_not_played {
  color: black !important;
  font-weight: bold;
}

.color_class_paid {
  color: darkgreen !important;
  font-weight: bold;
}

.color_class_not_paid {
  color: lightgreen !important;
}

.color_class_lost {
  color: gray !important;
}

.color_class_rejected {
  color: red !important;
}

.statistic {
  margin: 10px 0;
  border-color: #999999;
}

.statistic th {
  color: #999999;
}

.statistic td {
  text-align: center;
}
</style>