<template>
  <p></p>
  <Filters
      :helpers="true"
      @onRunReport="runReport"
      :exportbut="rows.length>0"
      @onDownloadFile="downloadFile"
  />
  <p></p>
  <vue-good-table v-if="rows.length>0"
    ref="table"
    styleClass="vgt-table nowrap bordered"
    :columns="columns"
    :rows="rows"
    :pagination-options="{
      enabled: true,
      perPage: 1000,
      perPageDropdown: perPageDropdown,
      nextLabel: $t('app.vue-good-table-next.nextLabel'),
      prevLabel: $t('app.vue-good-table-next.prevLabel'),
      rowsPerPageLabel: $t('app.vue-good-table-next.rowsPerPageLabel'),
      ofLabel: $t('app.vue-good-table-next.ofLabel'),
      pageLabel: $t('app.vue-good-table-next.pageLabel'), // for 'pages' mode
      allLabel: $t('app.vue-good-table-next.allLabel')
    }"
    :row-style-class="rowStyleClassFn"
    compactMode>
    <template #emptystate>
      <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
    </template>
    <template #table-row="props">
        <span v-if="props.column.field == 'check_num'" @click="to_check(props.row.check_num)">
          <span class="link">{{props.row.check_num}}</span>
        </span>
      <span v-else :class="colStyleClassFn(props.row)">
        {{props.formattedRow[props.column.field]}}
      </span>
    </template>
  </vue-good-table>
  <div class="alert alert-info" v-if="rows.length<1">{{ $t('reports.reports.dataNotFound') }}</div>
</template>

<script setup>
import {inject, ref, onActivated, provide, computed, watch} from 'vue'
import {useStore} from "vuex";
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import ReportService from "../../../services/report.service";
import Filters from "@/components/Reports/Filters/filters.vue";

const langEl=inject('langEl')
const dataformatEl=inject('dataformatEl')

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')
const $i18n = inject('$i18n')

const store = useStore()

const set_result = inject('set_result')

const is_super = inject('is_super')

const setIsLoading = inject('setIsLoading')

function filtersFn()
{
  let res=[]

  res.push({type: 'datestart'})
  res.push({type: 'dateend'})
  res.push({type: 'timezone'})

  return res
}
const filters = computed(filtersFn)
provide('filters', filters)

const updateRunRep = inject('updateRunRep')
const updateTabActive = inject('updateTabActive')
const userfilterData = inject('filterData')
const userRunRep = inject('runRep')
const updatefilterData = inject('updatefilterData')
const downloadFileExcel = inject('downloadFileExcel')

const runProcess = inject('runProcess')

const data_type = inject('data_type')

const date_to_format = inject('date_to_format')

const tz_list = inject('tz_list')

const is_run = ref(false)
provide('is_run', is_run)

const but_run_block = ref(false)
const date_start = ref('')
const date_end = ref('')
const value = ref('')
const filter_res = ref('all')
const filter_res_tab = ref('')
const tz_res = ref('UTC+0')

const data = ref({
      'receipt_all': 0,
      'receipt_show': 0,
      'bets_sum': 0,
      'paid_cnt': 0,
      'paid_sum': 0,
      'profit': 0,
      'not_paid_cnt': 0,
      'not_paid_sum': 0,
      'rejected': 0
})

const to_date = inject('to_date')

function numbermore_cf(data, filterString)
{
  var x
  var y

  if(filterString!=null) {
    x = parseInt(filterString.toString().replaceAll(' ', ''))
  }

  if(data!=null) {
    y = parseInt(data.toString().replaceAll(' ', ''))
  }

  return y >= x;
}

const filtersOptions = ref({'table_name': [], 'box_public_id': [], 'cashier_login': []})

const perPageDropdown = inject('perPageDropdown')
const columns = computed(() => [
        {
          label: $t('reports.reportstabletransactionworkshift.table_name'),
          field: 'table_name',
          filterOptions: {
            enabled: true,
            placeholder: $t('reports.reports.all'),
            filterDropdownItems: filtersOptions.value.table_name
          },
        },
        {
          label: $t('reports.reportstabletransactionworkshift.bill_nameid'),
          field: 'bill_nameid',
          filterOptions: {
            enabled: true,
            placeholder: $t('reports.reports.all'),
            filterDropdownItems: filtersOptions.value.bill_nameid
          },
        },
        {
          label: $t('reports.reportstabletransactionworkshift.begin_date'),
          field: 'begin_date_ch',
        },
        {
          label: $t('reports.reportstabletransactionworkshift.end_date'),
          field: 'end_date_ch',
        },
        {
          label: $t('reports.reportstabletransactionworkshift.total_deposited_wallet'),
          field: 'total_deposited_wallet_ch',
          filterOptions: {enabled: true, filterFn: numbermore_cf, placeholder: $t('reports.reports.more')},
          tdClass: 'text-right'
        },
        {
          label: $t('reports.reportstabletransactionworkshift.total_deposited_cashier'),
          field: 'total_deposited_cashier_ch',
          filterOptions: {enabled: true, filterFn: numbermore_cf, placeholder: $t('reports.reports.more')},
          tdClass: 'text-right'
        },
        {
          label: $t('reports.reportstabletransactionworkshift.total_deposited_bonus'),
          field: 'total_deposited_bonus_ch',
          filterOptions: {enabled: true, filterFn: numbermore_cf, placeholder: $t('reports.reports.more')},
          tdClass: 'text-right'
        },
        {
          label: $t('reports.reportstabletransactionworkshift.total_paid'),
          field: 'total_paid_ch',
          filterOptions: {enabled: true, filterFn: numbermore_cf, placeholder: $t('reports.reports.more')},
          tdClass: 'text-right'
        },
        {
          label: $t('reports.reportstabletransactionworkshift.balance'),
          field: 'balance_ch',
          filterOptions: {enabled: true, filterFn: numbermore_cf, placeholder: $t('reports.reports.more')},
          tdClass: 'text-right'
        }
      ])

const rows = ref([])

function rowStyleClassFn(row)
{
  return 'color_class_'+row.result_code
}

function colStyleClassFn(row)
{
  let color_class='color_class_'+row.type_transactions

  if (row.result_code=='win') {
    color_class=color_class+'_'+row.status_check
  }

  return color_class
}

function runReport()
{
  setIsLoading(true)

  but_run_block.value=true

  let dstart=date_to_format(userfilterData.ds)
  let dend=date_to_format(userfilterData.de)

  let bills=[];
  for(let key in store.state.bill.selBills) {
    let cur=store.state.bill.selBills[key];
      bills.push(cur)
  }

  rows.value=[]
  ReportService.getTableWorkShift(data_type.value, bills, dstart, dend, userfilterData.tz).then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        let cur = []

        let table_name = []
        let bill_nameid = []

        for(let key in response.data) {
          cur = response.data[key]
          rows.value.push(cur)

          if(cur['table_name']!==null) {
            table_name[cur['table_name']]=cur['table_name']
          }

          if(cur['bill_nameid']!==null) {
            bill_nameid[cur['bill_nameid']]=cur['bill_nameid']
          }
        }

        filtersOptions.value.table_name = Object.keys(table_name)
        filtersOptions.value.bill_nameid = Object.keys(bill_nameid)

        filtersOptions.value.table_name.sort()
        filtersOptions.value.bill_nameid.sort()

        is_run.value=true
        but_run_block.value=false
        setIsLoading(false)
        runProcess.value=true
      },
      (error) => {
        set_result(error.response.status, error)
        but_run_block.value=false
        setIsLoading(false)
        runProcess.value=true
      }
  )
}

function setPeriod(date)
{
  updatefilterData({"ds": date.date_s, "de": date.date_e})
}
provide('setPeriod', setPeriod)

function to_check(dt)
{
  updatefilterData({"chn": dt})

  updateTabActive('view-receipt')
  updateRunRep(true)
}

const table = ref(null)
function downloadFile()
{
  let replace_filed = {
    balance_ch: 'balance',
    total_deposited_cashier_ch: 'total_deposited_cashier',
    total_deposited_wallet_ch: 'total_deposited_wallet',
    total_paid_ch: 'total_paid',
  }

  downloadFileExcel(table.value.filteredRows[0].children, columns.value, 'shifts', replace_filed)
}

const currentTab = inject('currentTab')
const i18nlocale = computed(() => $i18n.locale)

function onChangeLang()
{
  if(currentTab.value=='detailed') {
    runReport()
  }
}
watch(i18nlocale, onChangeLang)

onActivated(() => {
  if(userRunRep.value) {
    if (userfilterData.paid_status!='all') {
      filter_res_tab.value=$t('reports.reportsdetailed.'+userfilterData.paid_status)
    } else {
      filter_res_tab.value=''
    }
    runReport()
    updateRunRep(false)
  }
})
</script>

<style>
.color_class_box_refill_by_acceptor, .color_class_box_refill_by_cashier {
  color: green !important;
}

.color_class_box_withdraw_by_cashier {
  color: red !important;
}

.statistic {
  margin: 10px 0;
  border-color: #999999;
}

.statistic th {
  color: #999999;
}

.statistic td {
  text-align: center;
}
</style>