<template>
  <p></p>
  <Filters
      :helpers="true"
      @onRunReport="runReport"
  />
  <p></p>
  <div>
    <div v-if="cntrep>0 && firstRun==true"><img height="50" src="@/assets/loading.gif"/></div>
    <el-table
        ref="tableEl"
        v-if="rowsTotal.length>0 && (cntrep==0 || firstRun==false)"
        :data="rowsTotal"
        style="width: 100%"
        border
        :scrollbar-always-on="true"
        :cell-class-name="cellStyleClassFnEl"
        @cell-click="cellClick"
    >
      <el-table-column v-for="column in columnsTotal" :key="column.field" :prop="column.field" :label="column.label" :min-width="column.minwidth" />
    </el-table>
    <hr>
    <p></p>
    <p v-if="rows.length>1 || isLoadingDivVirt"><b>{{ $t('reports.reportspublic.groupVirt') }}</b></p>
    <div v-if="isLoadingDivVirt"><img height="50" src="@/assets/loading.gif"/></div>
    <el-table
        ref="tableEl"
        v-if="rows.length>1"
        :data="rows"
        style="width: 100%"
        border
        :scrollbar-always-on="true"
        :cell-class-name="cellStyleClassFnEl"
        @cell-click="cellClick"
    >
      <el-table-column v-for="column in columns" :key="column.field" :prop="column.field" :label="column.label" :min-width="column.minwidth">
        <template v-if="column.field=='bill_nameid_group'" #default="props">
          <span v-for="n in props.row.level" :key="n" v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'"></span>
          {{props.row[column.field]}}<br>
          <span v-if="props.row.cnt_act_bill>0 && props.row.grpdt!='1'" @click="load_detail(props.row, props.$index)" class="link">
            <span v-for="n in props.row.level" :key="n" v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'"></span>Details ({{ props.row.cnt_act_bill }})
            <font-awesome-icon v-if="props.row.collapse!=true" icon="caret-right" />
            <font-awesome-icon v-if="props.row.collapse==true" icon="caret-down" />
          </span>
        </template>
      </el-table-column>
    </el-table>
    <hr>
    <p></p>
    <p v-if="(rowsTable.length>1 && tblCnt>0) || isLoadingDivTable"><b>{{ $t('reports.reportspublic.groupTable') }}</b></p>
    <div v-if="isLoadingDivTable && tblCnt>0"><img height="50" src="@/assets/loading.gif"/></div>
    <el-table
        ref="tableEl"
        v-if="rowsTable.length>1 && tblCnt>0"
        :data="rowsTable"
        style="width: 100%"
        border
        :scrollbar-always-on="true"
        :cell-class-name="cellStyleClassFnEl"
        @cell-click="cellClick"
    >
      <el-table-column v-for="column in columnsTable" :key="column.field" :prop="column.field" :label="column.label" :min-width="column.minwidth">
        <template v-if="column.field=='bill_name_full'" #default="props">
          <span v-for="n in props.row.level" :key="n" v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'"></span>
          {{props.row[column.field]}}<br>
          <span v-if="props.row.cnt_act_bill>0 && props.row.grpdt!='1'" @click="load_detail_table(props.row, props.$index)" class="link">
          <span v-for="n in props.row.level" :key="n" v-html="'&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'"></span>Details ({{ props.row.cnt_act_bill_for_detail }})
            <font-awesome-icon v-if="props.row.collapse!=true" icon="caret-right" />
            <font-awesome-icon v-if="props.row.collapse==true" icon="caret-down" />
          </span>
        </template>
      </el-table-column>
    </el-table>

    <div class="alert alert-info" v-if="rows.length<1 && rowsTable.length<1">{{ $t('reports.reportsoverview.dataNotFound') }}</div>
  </div>
</template>

<script setup>
import 'vue-good-table-next/dist/vue-good-table-next.css'
import {provide, inject, ref, computed, watchEffect, watch} from "vue";
import {VueGoodTable} from "vue-good-table-next";
import ReportService from "@/services/report.service";
import Filters from "@/components/Reports/Filters/filters.vue";

import {useStore} from "vuex";
import Loading from 'vue3-loading-overlay';

const langEl=inject('langEl')
const dataformatEl=inject('dataOnlyformatEl')

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')

const is_super = inject('is_super')

const store = useStore()

const set_result = inject('set_result')

const firstRun=ref(true)

const isLoadingDivVirt=ref(false)
const isLoadingDivTable=ref(false)
const setIsLoading = inject('setIsLoading')

const runProcess = inject('runProcess')

const data_type = inject('data_type')

function filtersFn()
{
  let res=[]

  res.push({type: 'datestart'})
  res.push({type: 'dateend'})
  res.push({type: 'timezone'})
  res.push({type: 'convert_to_usd'})


  return res
}
const filters = computed(filtersFn)
provide('filters', filters)

const userfilterData = inject('filterData')
const updatefilterData = inject('updatefilterData')
const downloadFileExcel = inject('downloadFileExcel')

const date_to_format = inject('date_to_format')

const tz_list = inject('tz_list')

const is_run = ref(false)
provide('is_run', is_run)

const rep_type_list = computed(()=>[
  {id: 'byData', name: $t('reports.reportsoverview.byData')},
  {id: 'byBillParent', name: $t('reports.reportsoverview.byBillParent')}
])
const rep_type = ref('byData')

const tblCnt=inject('tblCnt')
const cntrep=ref(0)

const date_start = ref('')
const date_end = ref('')
const tz_res = ref('UTC+0')

const perPageDropdown = inject('perPageDropdown')

function fieldsTotal()
{
  let fld = []

  fld.push({label: $t('reports.reportspublic.total.coming'), field: 'coming'})
  fld.push({label: $t('reports.reportspublic.total.consumption'), field: 'consumption'})
  fld.push({label: $t('reports.reportspublic.total.profit'), field: 'profit'})

  return fld
}
const columnsTotal = computed(fieldsTotal)

function fields()
{
  let fld = []

  fld.push({label: $t('reports.reportspublic.Agent'), field: 'bill_nameid_group', minwidth: '44'})
  fld.push({label: $t('reports.reportspublic.virt.StakeAmount'), field: 'sum_bets_ch', minwidth: '8'})
  fld.push({label: $t('reports.reportspublic.virt.StakesPaid'), field: 'sum_payout_amount_ch', minwidth: '8'})
  fld.push({label: $t('reports.reportspublic.virt.StakesNoPaid'), field: 'sum_nopayout_amount_ch', minwidth: '8'})
  fld.push({label: $t('reports.reportspublic.Profit'), field: 'profit_ch', minwidth: '8'})
  fld.push({label: $t('reports.reportspublic.virt.BetsAreDone'), field: 'cnt_check', minwidth: '8'})
  fld.push({label: $t('reports.reportspublic.virt.RatesPaid'), field: 'cnt_check_paid', minwidth: '8'})
  fld.push({label: $t('reports.reportspublic.virt.jackpot'), field: 'sum_jp', minwidth: '8'})

  return fld
}
const columns = computed(fields)

function fieldsTable()
{
  let fld = []

  fld.push({label: $t('reports.reportspublic.Agent'), field: 'bill_name_full', minwidth: '50'})
  fld.push({label: $t('reports.reportspublic.table.table_name'), field: 'table_name', minwidth: '10'})
  fld.push({label: $t('reports.reportspublic.table.sum_deposited_wallet'), field: 'sum_deposited_wallet_ch', minwidth: '10'})
  fld.push({label: $t('reports.reportspublic.table.sum_deposited_cashier'), field: 'sum_deposited_cashier_ch', minwidth: '10'})
  fld.push({label: $t('reports.reportspublic.table.sum_paid'), field: 'sum_paid_ch', minwidth: '10'})
  fld.push({label: $t('reports.reportspublic.Profit'), field: 'total_win_ch', minwidth: '10'})

  return fld
}
const columnsTable = computed(fieldsTable)

const level = ref(0)

const rowsTotal = ref([])
const rows = ref([])
const rowsTable = ref([])

function runReportVirt(list_bills=null, cur_el=null, level=0, sel_bill=null)
{
  isLoadingDivVirt.value=true
  cntrep.value++

  let dstart=date_to_format(userfilterData.ds)
  let dend=date_to_format(userfilterData.de)

  let bills=[];
  for(let key in store.state.bill.selBills) {
    let cur=store.state.bill.selBills[key];
    bills.push(cur)
  }

  if(list_bills!==null) {
    bills=JSON.parse(list_bills)
  }

  if(cur_el==null) {
    rows.value=[]
  }

  ReportService.getPublicVirt(data_type.value, bills, dstart, dend, userfilterData.tz, sel_bill, userfilterData.convert_to_usd).then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        if(cur_el==null) {
          rows.value=response.data
        } else {
          let blank_row=0
          for(let ii=0;ii<response.data.length; ii++) {
            response.data[ii].level=level+1
            rows.value.splice(cur_el+1+ii, 0, response.data[ii])
            blank_row=cur_el+1+ii
          }

          if(rows.value[blank_row+1].empty_lv==undefined) {
            rows.value.splice(blank_row+1, 0, {'empty_lv': level, 'level': level})
          }

          rows.value[cur_el].collapse=true
        }

        is_run.value=true
        isLoadingDivVirt.value=false
        cntrep.value--
      },
      (error) => {
        set_result(error.response.status, error)
        isLoadingDivVirt.value=false
        cntrep.value--
      }
  )
}

function runReportTable(list_bills=null, cur_el=null, level=0, sel_bill=null)
{
  isLoadingDivTable.value=true
  cntrep.value++

  let dstart=date_to_format(userfilterData.ds)
  let dend=date_to_format(userfilterData.de)


  let bills=[];
  for(let key in store.state.bill.selBills) {
    let cur=store.state.bill.selBills[key];
    bills.push(cur)
  }

  if(list_bills!==null) {
    bills=JSON.parse(list_bills)
  }

  if(cur_el==null) {
    rowsTable.value=[]
  }

  ReportService.getPublicTable(data_type.value, bills, dstart, dend, userfilterData.tz, sel_bill, userfilterData.convert_to_usd).then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        if(cur_el==null) {
          rowsTable.value=response.data
        } else {
          let blank_row=0
          for(let ii=0;ii<response.data.length; ii++) {
            response.data[ii].level=level+1
            rowsTable.value.splice(cur_el+1+ii, 0, response.data[ii])
            blank_row=cur_el+1+ii
          }

          if(rowsTable.value[blank_row+1].empty_lv==undefined) {
            rowsTable.value.splice(blank_row+1, 0, {'empty_lv': level, 'level': level})
          }

          rowsTable.value[cur_el].collapse=true
        }

        is_run.value=true
        isLoadingDivTable.value=false
        cntrep.value--
      },
      (error) => {
        set_result(error.response.status, error)
        isLoadingDivTable.value=false
        cntrep.value--
      }
  )
}

function runReport()
{
  firstRun.value=true

  runReportVirt()

  rowsTable.value=[]
  if(tblCnt.value>0) {
    runReportTable()
  }
}

function setPeriod(date)
{
  updatefilterData({"ds": date.date_s, "de": date.date_e})
}
provide('setPeriod', setPeriod)

function load_detail(data, index)
{
  if(data.level==undefined) {
    data.level=0
  }

  if(rows.value[index].collapse!=true) {
    runReportVirt(JSON.stringify(data.list_bill), index, data.level, data.bill_id_group)
  } else {
    let startlv=rows.value[index].level
    let curidx=index

    do
    {
      curidx++
    }
    while (rows.value[curidx]!==undefined && rows.value[curidx].level!==undefined && rows.value[curidx].level!=startlv)

    if(rows.value[curidx]!==undefined && rows.value[curidx].empty_lv==data.level) {
      curidx++
    }

    let res=rows.value.splice(index+1, curidx-index-1)

    rows.value[index].collapse=false
  }
}

function load_detail_table(data, index)
{
  if(data.level==undefined) {
    data.level=0
  }

  if(rowsTable.value[index].collapse!=true) {
    runReportTable(JSON.stringify(data.list_bill), index, data.level, data.bill_id_group)
  } else {
    let startlv=rowsTable.value[index].level
    let curidx=index

    do
    {
      curidx++
    }
    while (rowsTable.value[curidx]!==undefined && rowsTable.value[curidx].level!==undefined && rowsTable.value[curidx].level!=startlv)

    if(rowsTable.value[curidx]!==undefined && rowsTable.value[curidx].empty_lv==data.level) {
      curidx++
    }

    let res=rowsTable.value.splice(index+1, curidx-index-1)

    rowsTable.value[index].collapse=false
  }
}

function cellStyleClassFnEl(data)
{
  let resclass=''

  if(data.row.level>0 && data.row.empty_lv==undefined) {
    if (data.row.level % 2 == 0) {
      resclass = 'bgcolor_gray2'
    } else {
      resclass = 'bgcolor_gray1'
    }
  }

  if(data.column.rawColumnKey=='profit_ch') {
    if(data.row.profit>0) {
      resclass='bgcolor_up'
    }

    if(data.row.profit<0) {
      resclass='bgcolor_down'
    }

    if(data.row.profit==0) {
      resclass='bg-white'
    }
  }

  if(data.column.rawColumnKey=='total_win_ch') {
    if(data.row.total_win>0) {
      resclass='bgcolor_up'
    }

    if(data.row.total_win<0) {
      resclass='bgcolor_down'
    }

    if(data.row.total_win==0) {
      resclass='bg-white'
    }
  }

  if(data.row.grpdt=='1') {
    resclass+=' font_bold'
  }

  return resclass
}

function downloadFile()
{
  let replace_filed = {
    sum_bets_ch: 'sum_bets',
    sum_payout_amount_ch: 'sum_payout_amount',
    profit_ch: 'profit',
  }

  downloadFileExcel(rows.value, columns.value, 'overview', replace_filed)
}

function numberWithSpaces(x) {
  return x.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const formatFLoat=inject('formatFLoat')

function calcTotal()
{
  if(firstRun.value==true && (rows.value.length>0 || rowsTable.value.length>0)) {
    let totalVirt = {coming: 0, consumption: 0, profit: 0}
    let totalTable = {coming: 0, consumption: 0, profit: 0}

    if(rows.value.length>1) {
      let rv=rows.value.at(-1)
      totalVirt.coming = parseFloat(rv.sum_bets)
      totalVirt.consumption = parseFloat(rv.sum_payout_amount)
      totalVirt.profit = parseFloat(rv.profit)
    }

    if(rowsTable.value.length>1) {
      let rv=rowsTable.value.at(-1)
      totalTable.coming = parseFloat(rv.sum_deposited_cashier)+parseFloat(rv.sum_deposited_wallet)
      totalTable.consumption = parseFloat(rv.sum_paid)
      totalTable.profit = parseFloat(rv.total_win)
    }

    let coming = totalVirt.coming + totalTable.coming
    let consumption = totalVirt.consumption + totalTable.consumption
    let profit = totalVirt.profit + totalTable.profit

    coming = formatFLoat(coming)
    consumption = formatFLoat(consumption)
    profit = formatFLoat(profit)

    rowsTotal.value=[{coming: coming, consumption: consumption, profit: profit}]
  }

  if(firstRun.value==true && cntrep.value==0 && rowsTotal.value.length>0) {
    firstRun.value=false
    runProcess.value=true
  }
}
watchEffect(calcTotal)

function cellClick(row, column, event, cell)
{
  if(column.property=='profit') {
    let profit=row.profit
    profit=profit.replaceAll(' ', '')
    //profit=parseFloat(profit)
    profit=profit.replaceAll('.', ',')

    navigator.clipboard.writeText(profit)
  }

  if(column.property=='profit_ch') {
    let profit=row.profit_ch
    profit=profit.replaceAll(' ', '')
    //profit=parseFloat(profit)
    profit=profit.replaceAll('.', ',')

    navigator.clipboard.writeText(profit)
  }

  if(column.property=='total_win_ch') {
    let profit=row.total_win_ch
    profit=profit.replaceAll(' ', '')
    //profit=parseFloat(profit)
    profit=profit.replaceAll('.', ',')

    navigator.clipboard.writeText(profit)
  }
}
</script>
