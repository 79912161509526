<template>
  <highcharts :options="chartOptions"></highcharts>
</template>

<script setup>
import {computed, defineProps, inject, ref, watchEffect} from 'vue'

const $t = inject('$t')

//type spline column https://www.highcharts.com/demo  https://www.highcharts.com/docs/index
const props = defineProps({
  xAxis: Array,
  data: Array
})

const xAxis = ref([])

function chartOptionsFn()
{
  let res = {}

  res = {
    chart: {
      type: 'spline'
    },
    title: {
      text: ''
    },
    tooltip: {
      shared: true,
      crosshairs: true
    },
    legend: {
      borderWidth: 1,
      borderRadius: 5,
      backgroundColor: '#FFFFFF',
      shadow: true
    },
    /*plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          format: '{point.y:.1f}%'
        }
      }
    },*/
    xAxis: {
      categories: props.xAxis,
      crosshair: true
    },
    yAxis: [{ // Primary yAxis
      /*labels: {
        //format: '{value}°C',
        style: {
          color: 'red'
        }
      },*/
      title: {
        text: $t('reports.reportsoverview.StakeAmount')+' / '+$t('reports.reportsoverview.Profit'),
        /*style: {
          color: 'red'
        }*/
      }
    }, { // Secondary yAxis
      title: {
        text: $t('reports.reportsoverview.BetsAreDone'),
        style: {
          color: 'red'
        }
      },
      /*labels: {
        //format: '{value} mm',
        style: {
          color: 'red'
        }
      },*/
      opposite: true
    }],
    series: props.data
  }

  return res
}
const chartOptions = computed(chartOptionsFn)

</script>
<style>
</style>
