<template>
  <p></p>
  <Filters
      :helpers="true"
      @onRunReport="runReport"
      :exportbut="rows.length>0"
      @onDownloadFile="downloadFile"
  />
  <p></p>
  <div class="alert alert-info" v-if="rows.length<1">{{ $t('reports.reportsdetailed.dataNotFound') }}</div>

  <div class="sidebar" v-on:scroll.passive="onScroll">
    <vue-good-table v-if="rows.length>0"
                    ref="table"
                    styleClass="vgt-table nowrap bordered"
                    :columns="columns"
                    :rows="rows_part"
                    v-on:column-filter="onColumnFilter"
                    :row-style-class="rowStyleClassFn"
                    compactMode>
      <template #emptystate>
        <div class="vgt-center-align vgt-text-disabled">{{ $t('app.vue-good-table-next.empty') }}</div>
      </template>
      <template #table-row="props">
          <span v-if="props.column.field == 'check_num'" @click="to_check(props.row.check_num)">
            <span class="link">{{props.row.check_num}}</span>
          </span>
        <span v-else :class="colStyleClassFn(props.row)">
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script setup>
import {inject, ref, onActivated, provide, computed, watch} from 'vue'
import {useStore} from "vuex";
import { VueGoodTable } from 'vue-good-table-next';
import 'vue-good-table-next/dist/vue-good-table-next.css'
import ReportService from "../../../services/report.service";
import Filters from "@/components/Reports/Filters/filters.vue";

const langEl=inject('langEl')
const dataformatEl=inject('dataformatEl')

const verifyVer = inject('verifyVer')
const verifyVerData = inject('verifyVerData')

const $t = inject('$t')
const $i18n = inject('$i18n')

const store = useStore()

const set_result = inject('set_result')

const is_super = inject('is_super')

const setIsLoading = inject('setIsLoading')

const runProcess = inject('runProcess')

const data_type = inject('data_type')

const check_num_v = ref();

function filtersFn()
{
  let res=[]

  res.push({type: 'datestart'})
  res.push({type: 'dateend'})
  res.push({type: 'timezone'})

  return res
}
const filters = computed(filtersFn)
provide('filters', filters)

const updateRunRep = inject('updateRunRep')
const updateTabActive = inject('updateTabActive')
const userfilterData = inject('filterData')
const userRunRep = inject('runRep')
const updatefilterData = inject('updatefilterData')
const downloadFileExcel = inject('downloadFileExcel')

const date_to_format = inject('date_to_format')

const tz_list = inject('tz_list')

const is_run = ref(false)
provide('is_run', is_run)

const date_start = ref('')
const date_end = ref('')
const value = ref('')
const filter_res = ref('all')
const filter_res_tab = ref('')
const tz_res = ref('UTC+0')

const to_date = inject('to_date')

function prepareNum(dt)
{
  return parseFloat(dt.toString().replaceAll(' ', '').replaceAll(',', '.'))
}

function numbermore_cf(data, filterString)
{
  var x
  var y

  if(filterString!=null) {
    x = prepareNum(filterString)
  }

  if(data!=null) {
    y = prepareNum(data)
  }

  return y >= x;
}

function TableHeightDetFn()
{
  const pageHeight = document.documentElement.scrollHeight

  return pageHeight+"px"
}
const TableHeightDet = computed(TableHeightDetFn)
const filtersEl = ref({'jackpot_sum': ''})
const filtersOptionsEl = ref({'jackpot_type': [], 'game': [], 'bill': []})

const filtersOptions = ref({'jackpot_type': [], 'game': [], 'bill': []})

const perPageDropdown = inject('perPageDropdown')

function filterHandler(val, row, column)
{
  let property = column['property']

  return val===row[property]
}

function filterTableDataFn()
{
  let filterData = []

  for (let currow in rows.value) {
    let cur = rows.value[currow]
    let is_filter_ok=true

    //Ставка
    if(filtersEl.value.jackpot_sum!='') {
      if (numbermore_cf(cur.jackpot_sum, filtersEl.value.jackpot_sum) != true) {
        is_filter_ok = false
      }
    }

    if (is_filter_ok==true) {
      filterData.push(cur)
    }
  }

  return filterData
}

const filterTableData = computed(filterTableDataFn)

const rows = ref([])
const rowsForParts = ref([])

const PartNum=ref(0)
const PartPerCnt=ref(50)
const isAddPart=ref(false)
const rows_part = ref([])
function getPartData(all=false)
{
  rows_part.value=[]

  if(rowsForParts.value.length>0) {
    PartNum.value++
    let to=PartNum.value*PartPerCnt.value
    isAddPart.value=true
    if(to>rowsForParts.value.length || all===true) {
      to=rowsForParts.value.length
      isAddPart.value=false
    }

    for(let i=1;i<=to;i++) {
      rows_part.value.push(rowsForParts.value[i-1])
    }
  }
}

function onScroll(e)
{
  let chei=e.target.scrollHeight
  let cscrol=parseInt(e.target.scrollTop)+parseInt(e.target.clientHeight)
  let cscrolmin=cscrol-5
  let cscrolmax=cscrol+5
  if(chei>cscrolmin && chei<cscrolmax) {
    getPartData()
  }
}

function cellStyleClassFnEl(data)
{
  return 'color_class_'+data.row.result_code
}

function rowStyleClassFn(row)
{
  let resclass = 'color_class_'+row.result_code

  //if(['sum_bets_ch', 'payout_amount_ch'].includes(data.column.property)) {
  //  resclass+=' align_right'
  //}

  return resclass
}

function colStyleClassFn(row)
{
  let color_class='color_class_'+row.result_code

  if (row.result_code=='win') {
    color_class=color_class+'_'+row.status_check
  }

  return color_class
}

function runReport()
{
  setIsLoading(true)

  let dstart=date_to_format(userfilterData.ds)
  let dend=date_to_format(userfilterData.de)

  let bills=[];
  for(let key in store.state.bill.selBills) {
    let cur=store.state.bill.selBills[key];
    bills.push(cur)
  }

  rows.value=[]
  ReportService.getJackpot(data_type.value, bills, dstart, dend, userfilterData.tz, filter_res.value).then(
      (response) => {
        verifyVer(response.headers.ver)
        verifyVerData(response.headers.verdata)

        let cur = []
        let jackpot_type = []
        let game = []
        let bill = []

        for(let key in response.data) {
          cur = response.data[key]
          rows.value.push(cur)

          jackpot_type[cur['jackpot_type']]=cur['jackpot_type']
          game[cur['game']]=cur['game']
          bill[cur['bill']]=cur['bill']
        }

        PartNum.value=0
        rowsForParts.value=rows.value
        getPartData()

        filtersOptions.value.jackpot_type = Object.keys(jackpot_type)
        filtersOptions.value.game = Object.keys(game)
        filtersOptions.value.bill = Object.keys(bill)

        filtersOptions.value.jackpot_type.sort()
        filtersOptions.value.game.sort()
        filtersOptions.value.bill.sort()

        filtersOptionsEl.value.jackpot_type=[]
        for(let key in filtersOptions.value.jackpot_type) {
          cur = filtersOptions.value.jackpot_type[key]
          filtersOptionsEl.value.jackpot_type.push({ text: cur, value: cur })
        }

        filtersOptionsEl.value.game=[]
        for(let key in filtersOptions.value.game) {
          cur = filtersOptions.value.game[key]
          filtersOptionsEl.value.game.push({ text: cur, value: cur })
        }

        filtersOptionsEl.value.bill=[]
        for(let key in filtersOptions.value.bill) {
          cur = filtersOptions.value.bill[key]
          if(cur!=null) {
            filtersOptionsEl.value.bill.push({ text: cur, value: cur })
          }
        }

        //rows.value=response.data
        is_run.value=true
        setIsLoading(false)
        runProcess.value=true
      },
      (error) => {
        set_result(error.response.status, error)
        setIsLoading(false)
        runProcess.value=true
      }
  )
}

function setPeriod(date)
{
  updatefilterData({"ds": date.date_s, "de": date.date_e})
}
provide('setPeriod', setPeriod)

function to_check(dt)
{
  updatefilterData({"chn": dt})

  updateTabActive('view-receipt')
  updateRunRep(true)
}


function columnsFn()
{
  let coll=[]

  coll.push({
    label: $t('reports.reportsjackpot.bill'),
    field: 'bill',
    filterOptions: {
      enabled: true,
      placeholder: $t('reports.reportsdetailed.all'),
      filterDropdownItems: filtersOptions.value.bill
    },
  },
  {
    label: $t('reports.reportsjackpot.game'),
    field: 'game',
    filterOptions: {
      enabled: true,
      placeholder: $t('reports.reportsdetailed.all'),
      filterDropdownItems: filtersOptions.value.game
    },
  },
  {
    label: $t('reports.reportsjackpot.date'),
    field: 'date',
  },
  {
    label: $t('reports.reportsjackpot.jackpot_type'),
    field: 'jackpot_type',
    filterOptions: {
      enabled: true,
      placeholder: $t('reports.reportsdetailed.all'),
      filterDropdownItems: filtersOptions.value.jackpot_type
    },
  })

  if(is_super.value==true) {
    coll.push({
      label: $t('reports.reportsjackpot.jackpot_id'),
      field: 'jackpot_id',
      tdClass: 'text-right'
    })
  }

  coll.push({
    label: $t('reports.reportsjackpot.jackpot_sum'),
    field: 'jackpot_sum_ch',
    tdClass: 'text-right',
    filterOptions: {enabled: true, filterFn: numbermore_cf, placeholder: $t('reports.reportsdetailed.more')},
  },
  {
    label: $t('reports.reportsjackpot.recipe'),
    field: 'check_num',
  })

  return coll
}

const columns = computed(columnsFn)

const table=ref(null)
function downloadFile()
{
  let replace_filed = {
    jackpot_sum_ch: 'jackpot_sum',
  }

  downloadFileExcel(table.value.filteredRows[0].children, columns.value, 'jackpot', replace_filed)
}

const currentTab = inject('currentTab')
const i18nlocale = computed(() => $i18n.locale)

function onChangeLang()
{
  if(currentTab.value=='detailed') {
    runReport()
  }
}
watch(i18nlocale, onChangeLang)

onActivated(() => {
  if(userRunRep.value) {
    if (userfilterData.paid_status!='all') {
      filter_res_tab.value=$t('reports.reportsdetailed.'+userfilterData.paid_status)
    } else {
      filter_res_tab.value=''
    }
    runReport()
    updateRunRep(false)
  }
})
</script>

<style scoped>
.sidebar {
  height: 70vh;
  overflow-y: auto;
}
</style>

<style>
.link {
  cursor: pointer;
  color: blue;
}

.color_class_not_played {
  color: black !important;
  font-weight: bold;
}

.color_class_paid {
  color: darkgreen !important;
  font-weight: bold;
}

.color_class_not_paid {
  color: lightgreen !important;
}

.color_class_lost {
  color: gray !important;
}

.color_class_rejected {
  color: red !important;
}

.statistic {
  margin: 10px 0;
  border-color: #999999;
}

.statistic th {
  color: #999999;
}

.statistic td {
  text-align: center;
}
</style>
